import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { useEffect } from 'react';
import {
  getAccountsAction,
  getDepartmentsAction,
  getProgramsAction,
} from 'src/redux/actions/dataActions';
import { FORM_ID } from 'src/models/FormModel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: '8px',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  minWidth: {
    minWidth: '480px',
  },
}));

export enum MetadataType {
  department = 'departments',
  program = 'programs',
  account = 'accounts',
}

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  id?: string,
  margin?: 'dense' | 'normal' | undefined,
  name: string,
  noMarginTop?: boolean
  noMinWidth?: boolean
  onChange: (id:number) => void,
  type: MetadataType,
  value?: number,
}

interface DataItem {
  id: number,
  label?: string,
  description?: string,
}

const MetadataSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    disabled,
    form,
    id,
    margin,
    name,
    noMarginTop,
    noMinWidth,
    onChange,
    type,
    value,
  } = props;

  const data = useSelector((state: Store) => state.data[type]);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => {
    switch (type) {
      case MetadataType.program:
        dispatch(getProgramsAction());
        break;
      case MetadataType.department:
        dispatch(getDepartmentsAction());
        break;
      default:
        dispatch(getAccountsAction());
    }
  }, []);

  const getItems = () => {
    const items:DataItem[] = [{ id: 0 }];
    if (!data || !data.length) {
      return items;
    }
    data.sort(
      (a, b) => a[`name_${lang}`].localeCompare(b[`name_${lang}`]),
    ).forEach((item: any) => {
      items.push({
        id: item.id,
        label: item[`name_${lang}`],
        description: item[`description_${lang}`],
      } as DataItem);
    });
    return items;
  };

  const onValueChange = (item: DataItem) => {
    if (!item) {
      onChange(0);
      return null;
    }
    onChange(item.id as number);
    return null;
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as DataItem;
    if (!data || !data.length) {
      return result;
    }
    data.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: item[`name_${lang}`],
        } as DataItem;
      }
    });
    return result;
  };

  const displayLabel = (option:DataItem) => {
    if (option.label) {
      return (
        <Tooltip
          title={option.description as string}
          placement="top-start">
          <span>{ option.label }</span>
        </Tooltip>
      );
    }
    return (<></>);
  };

  const getOptionLabel = (option: DataItem) => option.label || '';

  const getClasses = () => {
    const noMarginTopClass = noMarginTop ? '' : classes.marginTop;
    const noMinWidthClass = noMinWidth ? '' : classes.minWidth;
    return `${noMarginTopClass} ${noMinWidthClass} ${classes.formControl}`;
  };

  return (
    <FormControl
      id={id}
      variant="outlined"
      className={classes.formControl}>
      <Autocomplete
        disabled={disabled}
        className={getClasses()}
        value={getValue(value)}
        onChange={(e, item) => onValueChange(item as DataItem)}
        options={getItems() || []}
        getOptionLabel={(option: DataItem) => getOptionLabel(option)}
        getOptionSelected={(option, item) => option.id === item.id}
        renderOption={(option: DataItem) => displayLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t(lang, `forms.invoices.${type}`)}
            placeholder={t(lang, `forms.invoices.${type}`)}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].join(',')}
            margin={margin || 'normal'} />
        )} />
    </FormControl>
  );
};

export default MetadataSelector;
