import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// import { getUserDataAction } from 'src/redux/actions/userActions';

import { Route } from 'src/constants/routes';

import { AdminMenu } from 'src/components/Menu/AdminMenu';
import Footer from 'src/components/UI/Footer';
import Content from 'src/components/Layout/Content';
import Main from 'src/components/Layout/Main';
import Logo from 'src/components/UI/Logo';
import OrganisationSelector from 'src/components/Control/OrganisationSelector';
import LanguageSelector from 'src/components/Control/LanguageSelector';
import ProfileButton from 'src/components/Control/ProfileButton';
import ProfilePage from 'src/components/Pages/ProfilePage';
import ContactsPage from 'src/components/Pages/ContactsPage';
import UserPaymentSettingsPage from 'src/components/Pages/UserPaymentSettingsPage';
import InvoicesPage from 'src/components/Pages/InvoicesPage';
import PaymentsPage from 'src/components/Pages/PaymentsPage';
import { LanguageModel } from 'src/models/LanguageModel';
import { setLanguage, setUserLocaleData } from 'src/redux/actions/languageActions';
import { getCustomFieldsAction } from 'src/redux/actions/customfieldActions';
import { getContactGroupAction } from 'src/redux/actions/contactGroupActions';
import SettingsPage from 'src/components/Pages/SettingsPage';
import { useMediaQuery } from 'react-responsive';
import PageBackground from 'src/components/Elements/PageBackground';
import OrganisationsPage from 'src/components/Pages/OrganisationsPage';
import { UserMenu } from 'src/components/Menu/UserMenu';
import { closeMenu, openMenu } from 'src/redux/actions/menuActions';
import { UIType } from 'src/constants/UIType';
import UserInvoicesPage from 'src/components/Pages/UserInvoicesPage';
import UserPaymentsPage from 'src/components/Pages/UserPaymentsPage';
import UserReceiptsPage from 'src/components/Pages/UserReceiptsPage';
import DashboardPage from 'src/components/Pages/DashboardPage';
import OnboardingPage from 'src/components/Pages/OnboardingPage';
import { useHotkeys } from 'react-hotkeys-hook';
import FundraisingPage from 'src/components/Pages/FundraisingPage';
import { t } from 'src/lib/language';
import InvoicesContactPage from 'src/components/Pages/InvoicesContactPage';
import InvoicesPaymentPage from 'src/components/Pages/InvoicesPaymentPage';
import PaymentsContactPage from 'src/components/Pages/PaymentsContactPage';
import PaymentsInvoicePage from 'src/components/Pages/PaymentsInvoicePage';
import palette from 'src/styles/palette';
import ApplicationBar from 'src/components/Elements/ApplicationBar';

interface Props {
  content: string
}

export default function PageLayout(props: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const { content } = props;

  const logged = useSelector((state: Store) => state.auth.accessToken);
  const open = useSelector((state: Store) => state.menu);
  const languageData = useSelector((state: Store) => state.language);
  const ui = useSelector((state: Store) => state.interface.ui);
  const currentLanguage = languageData.language || 'en';
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const organisation = useSelector((state: Store) => state.organisation.legalName);
  const dispatch = useAppDispatch();

  const dispatchContactAction = () => {
    dispatch(getCustomFieldsAction());
    dispatch(getContactGroupAction());
  };

  useEffect(() => {
    switch (content) {
      case Route.CONTACTS:
        dispatchContactAction();
        break;
      default:
    }
  }, []);

  useHotkeys('alt+m', () => (open ? dispatch(closeMenu()) : dispatch(openMenu())));

  const getVariant = () => (isTabletOrMobile ? 'temporary' : 'permanent');

  const toggleDrawer = (state: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown'
    ) {
      if (
        (event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift'
      ) {
        return;
      }
    }
    if (state) {
      dispatch(openMenu());
      return;
    }
    dispatch(closeMenu());
  };

  const displayMenu = () => {
    if (ui === UIType.admin) {
      return (<AdminMenu content={content} />);
    }
    return (<UserMenu content={content} />);
  };

  const displayTitle = () => {
    const title = t(lang, `pages.titles.${content}`);
    document.title = `${organisation}/${title}`;
    return (
      <Typography
        variant="h5"
        color="secondary"
        gutterBottom
        style={{
          fontSize: '1.8rem',
          fontFamily: 'Qanelas-Bold',
          marginBottom: '8px',
        }}>
        { t(lang, `pages.titles.${content}`) }
      </Typography>
    );
  };

  const displayContent = () => {
    switch (content) {
      case Route.PROFILE:
        return (
          <>
            {displayTitle() }
            <ProfilePage />
          </>
        );
      case Route.ORGANISATIONS:
        return (
          <>
            {displayTitle() }
            <OrganisationsPage />
          </>
        );
      case Route.CONTACTS:
        return (
          <>
            {displayTitle() }
            <ContactsPage />
          </>
        );
      case Route.INVOICES_CONTACT:
        return (
          <>
            <InvoicesContactPage />
          </>
        );
      case Route.INVOICES_PAYMENT:
        return (
          <>
            <InvoicesPaymentPage />
          </>
        );
      case Route.INVOICES:
        return (
          <>
            {displayTitle() }
            <InvoicesPage />
          </>
        );
      case Route.PAYMENTS:
        return (
          <>
            {displayTitle() }
            <PaymentsPage />
          </>
        );
      case Route.PAYMENTS_INVOICE:
        return (
          <>
            <PaymentsInvoicePage />
          </>
        );
      case Route.PAYMENTS_CONTACT:
        return (
          <>
            <PaymentsContactPage />
          </>
        );
      case Route.FUNDRAISING:
        return (
          <>
            {displayTitle() }
            <FundraisingPage />
          </>
        );
      case Route.SETTINGS:
        return (
          <>
            {displayTitle() }
            <SettingsPage />
          </>
        );
      case Route.ONBOARDING:
        return (
          <>
            {displayTitle() }
            <OnboardingPage />
          </>
        );
      case Route.USER_INVOICES:
        return <UserInvoicesPage />;
      case Route.USER_PAYMENTS:
        return <UserPaymentsPage />;
      case Route.USER_RECEIPTS:
        return <UserReceiptsPage />;
      case Route.USER_PAYMENT_SETTINGS:
        return <UserPaymentSettingsPage />;
      default:
        return (
          <>
            {displayTitle() }
            <DashboardPage />
          </>
        );
    }
  };

  const onLanguageChange = (language: LanguageModel) => {
    dispatch(setLanguage(language));
    if (logged) {
      dispatch(setUserLocaleData(language.locale));
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '100vh' }}>
        <CssBaseline />
        <ApplicationBar>
          <Toolbar style={{ minHeight: '56px' }}>
            <IconButton
              id="open-drawer-icon"
              data-testid="open-drawer-icon"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(!open)}>
              <MenuIcon style={{ display: open ? 'none' : 'inline-block' }} />
              <ChevronLeftIcon style={{ display: open ? 'inline-block' : 'none' }} />
            </IconButton>
            <Logo />
            Organisation:&nbsp;<OrganisationSelector />
            <LanguageSelector onChange={onLanguageChange} current={currentLanguage} />
            <ProfileButton />
          </Toolbar>
        </ApplicationBar>
        <Drawer
          variant={getVariant()}
          PaperProps={{
            style: {
              position: 'fixed',
              whiteSpace: 'nowrap',
              width: open ? '240px' : 0,
              overflowX: open ? 'visible' : 'hidden',
              transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
            },
          }}
          open={open}
          onClose={toggleDrawer(false)}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            minHeight: '64px',
          }} />
          <List>
            {displayMenu()}
          </List>
        </Drawer>
        <Main>
          <div style={{ minHeight: '0' }} />
          <Content style={{
            flex: 1,
            padding: isTabletOrMobile ? '' : '48px 32px',
            background: palette.background.default,
            width: '100%',
          }}>
            <div id="top" />
            <PageBackground>
              { displayContent() }
            </PageBackground>
          </Content>
          <Footer />
        </Main>
      </div>
    </div>
  );
}
