export enum FORM {
  add_customfield = 'add_customfield',
  add_organisation_bankaccount = 'add_organisation_bankaccount',
  add_relation = 'add_relation',
  add_contact_relation = 'add_contact_relation',
  edit_contact_relation = 'edit_contact_relation',
  delete_contact_relation = 'delete_contact_relation',
  edit_customfield = 'edit_customfield',
  edit_relation = 'edit_relation',
  delete_customfield = 'delete_customfield',
  delete_tax = 'delete_tax',
  manage_layout = 'manage_layout',
  manage_filter = 'manage_filter',
  add_invoice = 'add_invoice',
  add_contact = 'add_contact',
  contact = 'contact',
  download = 'download',
  export_invoice = 'export_invoice',
  export_payment = 'export_payment',
  cancel_payment = 'cancel_payment',
  refund_payment = 'refund_payment',
  edit_invoice = 'edit_invoice',
  cancel_invoice = 'cancel_invoice',
  edit_payment = 'edit_payment',
  edit_contact = 'edit_contact',
  get_bank_accounts = 'get_bank_accounts',
  get_user_bank_accounts = 'get_user_bank_accounts',
  get_contact = 'get_contact',
  get_contact_credit_cards = 'get_contact_credit_cards',
  get_user_credit_cards = 'get_user_credit_cards',
  get_customfields = 'get_customfields',
  get_invoice = 'get_invoice',
  get_payment = 'get_payment',
  get_taxes = 'get_taxes',
  get_products = 'get_products',
  invoice = 'invoice',
  invoice_get_contact = 'invoice_get_contact',
  invoice_get_payer = 'invoice_get_payer',
  invoice_get_seller = 'invoice_get_seller',
  login = 'login',
  make_payment = 'make_payment',
  make_donation = 'make_donation',
  admin_donation = 'admin_donation',
  merge_contact = 'merge_contact',
  post_contact = 'post_contact',
  post_purchase = 'post_purchase',
  post_tax = 'post_tax',
  put_invoice = 'put_invoice',
  put_payment = 'put_payment',
  put_bank_account = 'put_bank_account',
  put_contact = 'put_contact',
  put_tax = 'put_tax',
  query_builder = 'query_builder',
  request_reset_password = 'request_reset_password',
  reset_password = 'reset_password',
  user_organisation_share = 'user_organisation_share',
  user_organisation_add = 'user_organisation_add',
  user_organisation_delete = 'user_organisation_delete',
  register = 'register',
  verify = 'verify',
  verifying = 'verifying',
  invoice_contact = 'invoice_contact',
  add_creditcard = 'add_creditcard',
  edit_creditcard = 'edit_creditcard',
  add_product = 'add_product',
  put_product = 'put_product',
  edit_bankaccount = 'edit_bankaccount',
  add_bankaccount = 'add_bankaccount',
  manage_account = 'manage_bankaccount',
  manage_program = 'manage_program',
  manage_department = 'manage_department',
  manage_product = 'manage_product',
  manage_admin_user = 'manage_admin_user',
  edit_organisation_info = 'edit_organisation_info',
  edit_organisation_address = 'edit_organisation_address',
  upload_image = 'upload_image',
  organisation_bank_account = 'organisation_bank_account',
  invoice_search = 'invoice_search',
  payment_search = 'payment_search',
  receipt_search = 'receipt_search',
  invite_admin = 'invite_admin',
  add_admin = 'add_admin',
  changeEmail = 'change_email',
  changePassword = 'change_password',
  createOrganisation = 'create_organisation',
  add_gateway = 'add_gateway',
  get_settings = 'get_settings',
  post_setting = 'post_setting',
  identifiers = 'identifiers',
  fundraising_general_info = 'fundraising_general_info',
  fundraising_notification = 'fundraising_notification',
  fundraising_product = 'fundraising_product',
  fundraising_info = 'fundraising_info',
  fundraising_standard_assets = 'fundraising_standard_assets',
  fundraising_matching_assets = 'fundraising_matching_assets',
  fundraising_teams = 'fundraising_teams',
  get_groups = 'get_groups',
  get_departments = 'get_departments',
  get_accounts = 'get_accounts',
  get_programs = 'get_programs',
  get_organisations = 'get_organations',
}

export type DeleteCustomfieldFormType = `delete-customfield-${number}`;
export type EditCustomfieldFormType = `edit-customfield-${number}`;
export type EditGroupFormType = `edit-group-${number}`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FORM_STATUS {
  error = 'error',
  processing = 'processing',
  success = 'success',
}
