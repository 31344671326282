import operators from 'src/i18n/fr/search/operators';
import errors from 'src/i18n/fr/search/errors';

const search = {
  errors,
  operators,
  and: 'ET',
  or: 'OU',
  true: 'VRAI',
  false: 'FAUX',
  quickSearch: 'Recherche Rapide',
  advancedSearch: 'Recherche Advancee',
  selectOperator: 'Selectionner un operator',
  selectField: 'Selectionner un champ',
  search: 'Rechercher',
  reset: 'Initialise',
  addFilter: 'Ajouter un filtre',
};

export default search;
