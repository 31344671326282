import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { sortBy } from 'lodash';
import OrganisationCard from 'src/components/Elements/OrganisationCard';
import { postOrganisationsUserAction } from 'src/redux/actions/organisationActions';
import { TextField } from '@material-ui/core';
import Row from 'src/components/UI/Row';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { OrganisationItemModel } from 'src/models/OrganisationListModel';
import { useMediaQuery } from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  handleClose: () => void,
}

const OrganisationList = (props: Props) => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const MAX_ITEMS = 16;

  const lang = useSelector((state: Store) => state.language.language);
  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.get_organisations] === FORM_STATUS.processing,
  );

  const [items, setItems] = useState([] as OrganisationItemModel[]);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);
  const organisations = useSelector((state: Store) => state.organisationList);

  const getFiltered = () => {
    const sorted = sortBy(
      Object.values(organisations), (group) => group[`name_${lang}`],
    ).filter(
      (organisation) => (
        !organisationsUserList[organisation.id] && !organisation.hide
      ),
    );
    if (filter) {
      return sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(filter.toLowerCase()),
      );
    }
    return sorted;
  };
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0; // Reset vertical scroll
    }
  };

  useEffect(() => {
    if (page > 10) return;
    const filteredItems = getFiltered();
    setPage(1);
    setHasMore(filteredItems.length > MAX_ITEMS);
    setItems(filteredItems.slice(0, MAX_ITEMS));
  }, [organisations, filter]);

  useEffect(() => {
    if (page > 1) {
      const filteredItems = getFiltered();
      const newItems = filteredItems.slice((page - 1) * MAX_ITEMS, page * MAX_ITEMS);
      setItems((prevItems) => [...prevItems, ...newItems]);
      setHasMore(filteredItems.length > page * MAX_ITEMS);
    }
  }, [page]);

  const { handleClose } = props;

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const threshold = 10; // Adjust this value as needed

    if (scrollHeight - (scrollTop + clientHeight) < threshold) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page to load more items
      }
    }
  }, [hasMore]);

  const handleSearch = (term: string) => {
    resetScroll();
    setFilter(term);
  };

  const handleCardClick = (id:string) => {
    dispatch(postOrganisationsUserAction(id));
    handleClose();
  };

  const getItems = () => {
    if (loading) {
      return (
        <div style={{ width: '40px', margin: '0 auto', marginTop: '32px' }}>
          <CircularProgress size={40} />
        </div>
      );
    }
    return items.map((organisation) => (
      <OrganisationCard
        name={organisation[`name_${lang}`]}
        key={organisation.id}
        onClick={() => handleCardClick(organisation.id)} />
    ));
  };

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
        overflowY: 'scroll',
        height: '621px',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      overflowY: 'scroll',
      maxWidth: '680px',
      margin: 'auto',
      height: '621px',
    } as CSSProperties;
  };

  const listItems = () => (
    <div style={getListStyle()} onScroll={handleScroll} ref={scrollableDivRef}>
      {getItems()}
    </div>
  );

  return (
    <>
      <Row height="auto" style={{ marginBottom: '16px' }}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => handleSearch(e.target.value)} />
      </Row>
      { listItems() }
    </>
  );
};

export default OrganisationList;
