import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/UI/Subtitle';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { t } from 'src/lib/language';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import InvoiceValidator from 'src/validations/InvoiceValidator';
import InvoiceOrderValidator from 'src/validations/InvoiceOrderValidator';
import SimpleContactShippingValidator from 'src/validations/SimpleContactShippingValidator';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import {
  InvoiceModel,
  InvoiceOperations,
  OrderList,
  OrderModel,
  ProductOrderList,
  ProductOrderModel,
} from 'src/models/InvoiceModel';
import { getInvoiceAction, putInvoiceAction } from 'src/redux/actions/invoiceActions';
import {
  INVOICE_FIELDS,
  INVOICE_ORDER_FIELDS,
  INVOICE_PAYMENT_STATUS,
  INVOICE_PAYMENT_STATUS_COLOR,
  INVOICE_STATE,
} from 'src/constants/Invoices';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import OrdersDisplayer from 'src/components/Elements/OrderDisplayer';
import { MODALS_SIZE } from 'src/models/modal';
import {
  ceil, isArray, isEmpty, keyBy,
} from 'lodash';
import { ProductModel } from 'src/models/ProductModel';
import { ContactPayload } from 'src/models/ContactModel';
import { getContactAction } from 'src/redux/actions/contactActions';
import ContactSelector from 'src/components/Control/ContactSelector';
import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ManageAccountsButton from 'src/components/DatagridToolbar/buttons/ManageAccountsButton';
import ManageDepartmentsButton
  from 'src/components/DatagridToolbar/buttons/ManageDepartmentsButton';
import ManageProgramsButton from 'src/components/DatagridToolbar/buttons/ManageProgramsButton';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import MakePaymentButton from 'src/components/DatagridToolbar/buttons/MakePaymentButton';
import ManageProductsButton from 'src/components/DatagridToolbar/buttons/ManageProductsButton';
import DownloadSelectedInvoiceButton
  from 'src/components/DatagridToolbar/buttons/DownloadSelectedInvoiceButton';
import SendInvoiceByEmailButton
  from 'src/components/DatagridToolbar/buttons/SendInvoiceByEmailButton';
import DuplicateInvoiceButton from 'src/components/DatagridToolbar/buttons/DuplicateInvoiceButton';
import ShowPaymentsForInvoiceButton
  from 'src/components/DatagridToolbar/buttons/ShowPaymentsForInvoiceButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import AddInvoiceContactButton
  from 'src/components/DatagridToolbar/buttons/AddInvoiceContactButton';
import DeleteInvoiceButton from 'src/components/DatagridToolbar/buttons/DeleteInvoiceButton';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import ReplaceInvoiceDialog from 'src/components/Dialogs/ReplaceInvoiceDialog';
import { TaxItemModel } from 'src/models/TaxListModel';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { ProcessingType } from 'src/models/GatewayModel';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import PaginationUI from 'src/components/UI/PaginationUI';
import Scroller from 'src/components/UI/Scroller';
import Col2 from 'src/components/UI/Col2';
import Amount from 'src/components/UI/Amount';
import Form from 'src/components/UI/Form';
import Warning from 'src/components/UI/Warning';
import EditIcon from 'src/components/UI/EditIcon';

type Fields = INVOICE_ORDER_FIELDS | INVOICE_FIELDS;

interface TaxListModel {
  [id: number]: TaxItemModel;
}

const EditInvoiceForm = () => {
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const taxes = useSelector((state: Store) => {
    if (!state.taxes) return {} as TaxListModel;
    const result = {} as TaxListModel;
    state.taxes.forEach((tax) => {
      if (tax.id) result[tax.id] = tax;
    });
    return result;
  });
  const countries = useSelector(
    (state: Store) => keyBy(state.data.countries ?? [], 'id'),
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [contactId, setContactId] = useState(0);
  const [allowedFields, setAllowedFields] = useState([] as Array<string>);

  const getStatus = useSelector((state: Store) => state.formStatus[FORM.get_invoice]);
  const putStatus = useSelector((state: Store) => state.formStatus[FORM.put_invoice]);
  const processing = () => putStatus === FORM_STATUS.processing
    || getStatus === FORM_STATUS.processing;

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );

  const invoice = useSelector(
    (state: Store) => {
      if (!!state.invoices[selections[currentIndex] as number]) {
        return state.invoices[selections[currentIndex] as number] as InvoiceModel;
      }
      return {} as InvoiceModel;
    },
  );

  const { contact } = useSelector(
    (state: Store) => {
      if (!!state.contacts[contactId]) {
        return state.contacts[contactId] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const loaded = useSelector(
    (state: Store) => !!state.invoices[selections[currentIndex] as number],
  );

  const [orderState, setOrderState] = useState(
    invoice.order as ProductOrderList ?? [] as ProductOrderList,
  );

  const [invoiceState, setInvoiceState] = useState(invoice as InvoiceModel);

  const [invoiceDetailsViewState, setInvoiceDetailsViewState] = useState(true);

  const getAllowedFields = (currentInvoice: InvoiceModel) => {
    const invoiceFields = [
      ...Object.values(INVOICE_FIELDS),
      ...Object.values(InvoiceOperations),
    ];

    let allowedFieldList = invoiceFields;

    if (!currentInvoice.state) {
      setAllowedFields(allowedFields);
      return;
    }

    switch (currentInvoice.state) {
      case INVOICE_STATE.draft:
        allowedFieldList = invoiceFields;
        break;
      case INVOICE_STATE.canceled:
      case INVOICE_STATE.abandoned:
      case INVOICE_STATE.error:
        allowedFieldList = invoiceFields.filter((field) => field === InvoiceOperations.make_final);
        break;
      default:
        allowedFieldList = currentInvoice.payment_status === INVOICE_PAYMENT_STATUS.paid
          ? invoiceFields.filter((field) => field !== InvoiceOperations.delete_invoice)
          : invoiceFields;
    }

    setAllowedFields(allowedFieldList);
  };

  useEffect(() => {
    dispatch(setError({ [FORM.edit_invoice]: {} }));
    if (!selections[currentIndex]) {
      dispatch(closeModal({ modal: MODALS.editInvoice }));
    }
  }, []);

  useEffect(() => {
    dispatch(setError({ [FORM.edit_invoice]: {} }));
    if (selections[currentIndex]) {
      dispatch(getInvoiceAction(selections[currentIndex] as number));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    setInvoiceState({
      ...invoice,
      contact_payer_id: invoice.contact_payer_id,
    } as InvoiceModel);

    if (invoice.order) {
      const normalizedOrders = invoice.order.map(
        (orderItem) => (
          {
            ...orderItem,
            taxes: isArray(orderItem.taxes)
              ? orderItem.taxes
              : Object.keys(orderItem.taxes || []).map(Number),
          }
        ),
      );
      setOrderState([...normalizedOrders] as ProductOrderList);
    }

    setContactId(invoice.contact_id || 0);
    getAllowedFields(invoice);
  }, [loaded, invoice]);

  useEffect(() => {
    if (invoiceState.contact_id) {
      dispatch(getContactAction(invoiceState.contact_id, FORM.contact));
    }
  }, [contactId]);

  useEffect(() => {
    if (!!contact) {
      setInvoiceState({
        ...invoiceState,
        contact_payer_id: invoiceState.contact_payer_id ?? invoice.contact_payer_id,
      } as InvoiceModel);
    }
  }, [contact]);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setInvoiceState({
      ...invoiceState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const setShippingFromBilling = () => ({
    ...invoiceState,
    shipping_address: invoiceState.billing_address,
    shipping_suite: invoiceState.billing_suite,
    shipping_city: invoiceState.billing_city,
    shipping_state: invoiceState.billing_state,
    shipping_country_id: invoiceState.billing_country_id,
    shipping_zip_code: invoiceState.billing_zip_code,
  });

  const formatOrdersForValidation = (orders: any) => {
    const orderFields = {};

    orders.forEach((order: any, index: number) => {
      orderFields[`deductible_${index}`] = order.deductible;
      orderFields[`discount_${index}`] = order.discount;
      orderFields[`note_${index}`] = order.note;
      orderFields[`paid_${index}`] = order.paid;
      orderFields[`product_id_${index}`] = order.product_id;
      orderFields[`quantity_${index}`] = order.quantity;
      orderFields[`taxes_${index}`] = order.taxes;
    });

    return orderFields;
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setCurrentIndex(newPage - 1);
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editInvoice }));
  };

  const canCancelInvoice = () => {
    if (!!invoice.state && invoice.state === INVOICE_STATE.final) {
      if (
        invoice.issued_date !== invoiceState.issued_date
        || invoice.contact_first_name !== invoiceState.contact_first_name
        || invoice.contact_last_name !== invoiceState.contact_last_name
        || invoice.contact_company_name !== invoiceState.contact_company_name
      ) {
        if (
          invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled
          || invoice.payment_status === INVOICE_PAYMENT_STATUS.partially_paid
        ) {
          if (!!invoice.scheduled && invoice.scheduled > 0) {
            dispatch(setAlert({
              type: ALERT_TYPE.warning,
              code: t(lang, 'messages.invoice_with_scheduled_transactions'),
            }));
            return false;
          }
          if (!invoice.scheduled || invoice.scheduled === 0) {
            dispatch(setAlert({
              type: ALERT_TYPE.warning,
              code: t(lang, 'messages.abandonned_invoice'),
            }));
            return false;
          }
        }
      }
    }
    return true;
  };

  const canUpdateInvoice = () => {
    if (!!invoice.state && invoice.state === INVOICE_STATE.final) {
      if (
        invoice.issued_date !== invoiceState.issued_date
        || invoice.contact_first_name !== invoiceState.contact_first_name
        || invoice.contact_last_name !== invoiceState.contact_last_name
        || invoice.contact_company_name !== invoiceState.contact_company_name
      ) {
        dispatch(openDialog({
          dialog: DIALOGS.replaceInvoice,
          payload: {
            contact,
          },
        }));
        return false;
      }
    }

    return true;
  };

  const getTaxesForOrder = (order: ProductOrderModel):string => JSON.stringify(order.taxes);

  const denormalizeOrders = ():OrderList => ({
    ...orderState.map((order: ProductOrderModel) => ({
      ...order,
      deductible: (order.deductible ?? 0) / 100,
      taxes: getTaxesForOrder(order),
    } as OrderModel)),
  });

  const getTaxItem = (id: number) => taxes[id];

  const getSingleOrderSubtotal = (order: ProductOrderModel) => {
    const price =
      (order.quantity ?? 1) * (order.paid ?? 0) - (order.discount ?? 0);
    return ceil(
      price,
      2,
    );
  };

  const getName = (state: InvoiceModel) => {
    if (!!state.contact_company_name) {
      return state.contact_company_name;
    }
    return `${state.contact_last_name} ${state.contact_first_name}`;
  };

  const calculateTaxes = () => {
    const taxesObject = invoiceState.taxes || {};
    let totalTaxes = 0;
    Object.keys(taxesObject).forEach((taxId) => {
      totalTaxes += Number(taxesObject[taxId]);
    });
    return totalTaxes;
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    orderState.forEach((order) => {
      subtotal += getSingleOrderSubtotal(order);
    });
    return ceil(subtotal, 2);
  };

  const calculateTotal = () => ceil((calculateSubtotal() + calculateTaxes()), 2);

  const updateTotals = () => {
    setInvoiceState({
      ...invoiceState,
      total: calculateTotal(),
      subtotal: calculateSubtotal(),
    });
  };

  const onAddProduct = () => {
    setOrderState([
      ...orderState,
      {
        product_id: 0,
        paid: 0,
        quantity: 1,
      },
    ]);
  };

  const onDeleteProduct = (orderIndex: number) => {
    const orderList = [...orderState];
    orderList.splice(orderIndex, 1);
    setOrderState(orderList);
  };

  const onProductChange = (product?: ProductModel, index?: number) => {
    const orderList = [...orderState] as ProductOrderList;

    if (index !== undefined) {
      return;
    }
    const currentOrder = { ...orderState[index ?? 0] } as ProductOrderModel;
    if (product === undefined) {
      const newEmptyOrder = {
        ...currentOrder,
        [INVOICE_ORDER_FIELDS.product_id]: 0,
        [INVOICE_ORDER_FIELDS.paid]: 0,
        [INVOICE_ORDER_FIELDS.discount]: 0,
        [INVOICE_ORDER_FIELDS.deductible]: 0,
      } as ProductOrderModel;
      orderList.splice(index ?? 0, 1, newEmptyOrder);
      setOrderState(orderList);
      return;
    }

    const newOrder = {
      ...currentOrder,
      [INVOICE_ORDER_FIELDS.paid]: product.price || 0,
      [INVOICE_ORDER_FIELDS.discount]: product.discount || 0,
      [INVOICE_ORDER_FIELDS.deductible]: (product?.deductible || 0) * 100,
    } as ProductOrderModel;
    orderList.splice(index ?? 0, 1, newOrder);
    setOrderState(orderList);
    setInvoiceState({
      ...invoiceState,
      title: product[`name_${lang}`],
      program_id: product.program_id,
      account_id: product.account_id,
      department_id: product.department_id,
    });
  };

  const onUpdateProduct = (value: Value, field: string, index: number, isInvoice?: boolean) => {
    if (isInvoice) {
      setInvoiceState({
        ...invoiceState,
        [field]: value,
      });
      return;
    }

    const orderList = [...orderState];
    orderList[index][field] = value;
    setOrderState(orderList);
    updateTotals();
  };

  const onFieldChange = (value: Value, field: string) => {
    if (field === INVOICE_FIELDS.contact_id) {
      setContactId(value as number);
    }
    setInvoiceState({
      ...invoiceState,
      [field]: value,
    });
  };

  const onInstallmentChange = (installmentNumber: string) => {
    onFieldChange(parseInt(installmentNumber, 10), 'installments');
  };

  const onContactChange = (id: number, field: Fields) => {
    onFieldChange(id, field);
    setContactId(id);
    dispatch(getContactAction(id, FORM.edit_invoice));
  };

  const displayTaxes = () => {
    const taxesObject = invoiceState.taxes || {};
    return (
      <>
        { Object.keys(taxesObject).map((taxId: string, index) => {
          const tax = getTaxItem(Number(taxId));
          if (!tax) return null;
          return (
            <div key={`tax-item-${index}`} style={{ textAlign: 'right' }}>
              {tax[`name_${lang}`]} ({tax.rate * 100}%):&nbsp;
              <Amount>{formatter.format(taxesObject[taxId])}</Amount>
            </div>
          );
        }) }
      </>
    );
  };

  const displayInvoiceDetails = () => {
    if (isEmpty(invoiceState)) {
      return '';
    }
    return (
      <Row height="auto">
        <Col2>
          <b>{ t(lang, 'forms.invoices.billing_address') }:</b>
          <div>{ invoiceState.billing_address } ({ invoiceState.billing_suite })</div>
          <div>
            { invoiceState.billing_city },&nbsp;
            { invoiceState.billing_state }
          </div>
          <div>{ invoiceState.billing_zip_code }</div>
          <div>
            { `${!!countries[invoiceState.billing_country_id ?? 0] && countries[invoiceState.billing_country_id ?? 0].name_en}` }
          </div>
        </Col2>
        <Col2>
          <b>{ t(lang, 'forms.invoices.shipping_address') }:</b>
          <div>
            { invoiceState.shipping_address } ({ invoiceState.shipping_suite })
          </div>
          <div>
            { invoiceState.shipping_city },&nbsp;
            { invoiceState.shipping_state }
          </div>
          <div>{ invoiceState.shipping_zip_code }</div>
          <div>{ `${!!countries[invoiceState.shipping_country_id ?? 0] && countries[invoiceState.shipping_country_id ?? 0].name_en}` }</div>
        </Col2>
      </Row>
    );
  };

  const displayContactFullName = () => {
    if (isEmpty(invoice)) return <></>;
    return (
      <Row>
        <h3>
          {getName(invoiceState)}&nbsp;
          <EditIcon onClick={() => setInvoiceDetailsViewState(!invoiceDetailsViewState)} />
        </h3>
      </Row>
    );
  };

  const isDue = () => {
    switch (invoiceState.payment_status) {
      case INVOICE_PAYMENT_STATUS.paid:
      case INVOICE_PAYMENT_STATUS.scheduled:
        return false;
      default:
        if (!moment.isMoment(invoice.due_date)) return false;
        return moment(invoiceState.due_date).isBefore(moment());
    }
  };

  const hasWarning = () => {
    switch (invoice.payment_status) {
      case INVOICE_PAYMENT_STATUS.scheduled_due:
        return true;
      default:
        return false;
    }
  };

  const getStatusColor = () => {
    const color = INVOICE_PAYMENT_STATUS_COLOR[
      invoice.payment_status ?? INVOICE_PAYMENT_STATUS_COLOR.not_paid
    ];
    return hasWarning() ? '#d41d1d' : color;
  };

  const disableCurrentField = (field: string) => {
    if (processing()) return true;
    return !allowedFields.includes(field);
  };

  const isPaymentDisabled = () => (
    processing()
    || (invoiceState.payment_status === INVOICE_PAYMENT_STATUS.paid)
    || (invoiceState.state === INVOICE_STATE.draft)
    || disableCurrentField(InvoiceOperations.make_payment)
  );

  const handleSubmit = (
    event: MouseEvent<HTMLElement>,
    state: INVOICE_STATE,
    needConfirmation?: Boolean,
  ) => {
    event.preventDefault();
    dispatch(setError({ [FORM.edit_invoice]: {} }));

    if (!canUpdateInvoice() && (needConfirmation !== false)) {
      return false;
    }

    try {
      let schema;
      if (!invoiceState.sameAsBilling) {
        schema = InvoiceValidator(lang).concat(SimpleContactShippingValidator(lang));
      } else {
        schema = InvoiceValidator(lang);
      }
      schema.validateSync({ ...invoiceState }, { abortEarly: false });
      InvoiceOrderValidator(orderState, lang).validateSync(
        formatOrdersForValidation(orderState),
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.edit_invoice]: errorBag }));
      return false;
    }

    let currentInvoice = invoiceState;

    if (invoiceState.sameAsBilling) {
      currentInvoice = setShippingFromBilling();
    }

    const payload = {
      invoice: {
        ...currentInvoice,
        state,
      },
      order: denormalizeOrders(),
    };
    if (!payload.invoice.hasInstallments) {
      payload.invoice.installments = 0;
    }
    delete payload.invoice.hasInstallments;
    dispatch(putInvoiceAction(selections[currentIndex] as number, payload));
    return true;
  };

  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}
      disabled={processing()}>
      { t(lang, 'misc.close') }
    </Button>
  );

  const CancelButton = () => (
    <Tooltip
      title={t(lang, 'menus.delete_invoice')}
      placement="top-start">
      <span>
        <DeleteInvoiceButton
          disabled={processing() || !canCancelInvoice()}
          invoiceId={selections[currentIndex]} />
      </span>
    </Tooltip>
  );

  const SaveDraftButton = () => (
    <Tooltip
      title={t(lang, 'menus.save_as_draft')}
      placement="top-start">
      <span>
        <ActionButton
          disabled={processing()}
          category={MENU_BUTTON_CATEGORY.action}
          onClick={(e) => handleSubmit(e, INVOICE_STATE.draft)}>
          <SaveAltIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  const SaveFinalButton = () => (
    <Tooltip
      title={t(lang, 'menus.save_as_final')}
      placement="top-start">
      <span>
        <ActionButton
          disabled={processing() || disableCurrentField(InvoiceOperations.make_final)}
          category={MENU_BUTTON_CATEGORY.action}
          onClick={(e) => handleSubmit(e, INVOICE_STATE.final)}>
          <SaveIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Header height="86px">
        <Row>
          <Title>
            { t(lang, 'forms.invoices.edit_invoices') }&nbsp;
            #{ invoiceState.invoice_number }
          </Title>
          <CloseButton />
        </Row>
        <Row>
          <CancelButton />
          { (invoiceState.state === INVOICE_STATE.draft) && (
            <SaveDraftButton />
          ) }
          <SaveFinalButton />
          <DuplicateInvoiceButton
            invoice={invoiceState}
            disabled={disableCurrentField(InvoiceOperations.duplicate_invoice)} />
          <MakePaymentButton
            disabled={isPaymentDisabled()}
            invoiceId={invoiceState.id} />
          <ManageAccountsButton disabled={disableCurrentField(InvoiceOperations.manage_accounts)} />
          <ManageDepartmentsButton
            disabled={disableCurrentField(InvoiceOperations.manage_departments)} />
          <ManageProgramsButton
            disabled={disableCurrentField(InvoiceOperations.manage_programs)} />
          <ManageProductsButton
            disabled={disableCurrentField(InvoiceOperations.manage_products)} />
          <ShowPaymentsForInvoiceButton
            invoiceId={selections[currentIndex]}
            disabled={disableCurrentField(InvoiceOperations.show_invoice_payments)} />
          <AddInvoiceContactButton />
          <DownloadSelectedInvoiceButton
            invoiceId={selections[currentIndex]}
            disabled={disableCurrentField(InvoiceOperations.download_selected)} />
          <SendInvoiceByEmailButton
            invoiceId={selections[currentIndex]}
            disabled={disableCurrentField(InvoiceOperations.send_by_email)} />
        </Row>
      </Header>
      <Row style={{ marginTop: '4px', backgroundColor: 'white' }}>
        <span><b>{ t(lang, 'forms.invoices.paid') }:</b> { formatter.format(invoiceState.paid ?? 0) }</span>
        <span><b>{ t(lang, 'forms.invoices.scheduled') }:</b> { formatter.format(invoiceState.scheduled ?? 0) }</span>
        <span><b>Balance:</b> { formatter.format(invoiceState.balance ?? 0) }</span>
        <span>
          <b>{ t(lang, 'forms.invoices.payment_status') }:</b>&nbsp;
          { (hasWarning() || isDue()) && (
            <Warning />
          ) }
          <span
            style={{
              color: getStatusColor(),
            }}>
            { t(lang, `forms.invoices.${invoice.payment_status}`) }
          </span>
        </span>
        <span>
          <b>{ t(lang, 'forms.invoices.state') }:</b>&nbsp;
          <span>
            { t(lang, `forms.invoices.${invoice.state}`) }
          </span>
        </span>
      </Row>
      <PaginationUI
        count={selections.length}
        page={page}
        onChange={handlePageChange} />
      <Scroller>
        <Form maxWidth={MODALS_SIZE.large}>
          <Subtitle noMargin style={{ marginBottom: '8px' }}>
            {t(lang, 'forms.invoices.invoice_informations')}
          </Subtitle>
          <Row height="64px">
            <FormTextField
              form={FORM.add_invoice}
              label={t(lang, 'forms.invoices.title')}
              name={INVOICE_FIELDS.title}
              onChange={onFieldChange}
              required
              value={invoiceState.title || ''}
              disabled={disableCurrentField(INVOICE_FIELDS.title)} />
            <Col2>
              <FormDatePicker
                form={FORM.edit_invoice}
                required
                disabled={disableCurrentField(INVOICE_FIELDS.due_date)}
                error={isDue()}
                label={t(lang, 'forms.invoices.due_date')}
                name={INVOICE_FIELDS.due_date}
                onChange={
                    (value: MaterialUiPickersDate) => onFieldChange(
                      moment(value)
                        .format('YYYY-MM-DD'),
                      INVOICE_FIELDS.due_date,
                    )
                  }
                value={invoiceState.due_date || moment()} />
            </Col2>
            <Col2>
              <FormDatePicker
                form={FORM.edit_invoice}
                required
                disabled={disableCurrentField(INVOICE_FIELDS.issued_date)}
                label={t(lang, 'forms.invoices.issued_date')}
                name={INVOICE_FIELDS.issued_date}
                onChange={
                    (value: MaterialUiPickersDate) => onFieldChange(
                      value,
                      INVOICE_FIELDS.issued_date,
                    )
                  }
                value={invoiceState.issued_date || moment()} />
            </Col2>
          </Row>
          <Subtitle style={{ marginBottom: '16px' }}>
            {t(lang, 'forms.invoices.contacts')}
          </Subtitle>
          <Row height="96px" alignItems="start">
            <Col2>
              <ContactSelector
                autocompleteId={AUTOCOMPLETE_ID.edit_invoice_payer}
                onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.contact_payer_id)}
                label="Payer"
                disabled={disableCurrentField(INVOICE_FIELDS.contact_payer_id)}
                form={FORM.edit_invoice}
                name={INVOICE_FIELDS.contact_payer_id}
                contactId={invoiceState.contact_payer_id}
                hideEdit={!invoiceState.contact_payer_id} />
            </Col2>
            <Col2>
              <ContactSelector
                autocompleteId={AUTOCOMPLETE_ID.edit_invoice_seller}
                onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
                label="Seller"
                disabled={disableCurrentField(INVOICE_FIELDS.seller_id)}
                form={FORM.edit_invoice}
                name={INVOICE_FIELDS.seller_id}
                contactId={invoiceState.seller_id}
                hideEdit={!invoiceState.seller_id} />
            </Col2>
          </Row>
          <Row height="96px" alignItems="start">
            <Col2>
              <ContactSelector
                disabled={disableCurrentField(INVOICE_FIELDS.contact_id)}
                autocompleteId={AUTOCOMPLETE_ID.add_invoice_contact}
                onChange={(id: number) => onContactChange(id, INVOICE_FIELDS.contact_id)}
                label="Contact"
                form={FORM.edit_invoice}
                name={INVOICE_FIELDS.contact_id}
                contactId={invoiceState.contact_id}
                hideEdit={!invoiceState.contact_id} />
            </Col2>
          </Row>
          <Row height="64px">
            <Col2 style={{ marginTop: '-26px' }}>
              <GatewaySelector
                form={FORM.add_invoice}
                name="payment_gateway_eft_id"
                onChange={(value) => onFieldChange(value, 'payment_gateway_eft_id')}
                value={invoiceState.payment_gateway_eft_id}
                type={ProcessingType.eft}
                noMarginTop />
            </Col2>
            <Col2 style={{ marginTop: '-26px' }}>
              <GatewaySelector
                form={FORM.add_invoice}
                name="payment_gateway_cc_id"
                onChange={(value) => onFieldChange(value, 'payment_gateway_cc_id')}
                value={invoiceState.payment_gateway_cc_id}
                type={ProcessingType.cc}
                noMarginTop />
            </Col2>
          </Row>
          {displayContactFullName()}
          {(invoiceDetailsViewState) && displayInvoiceDetails()}
          {!(invoiceDetailsViewState) && (
          <div>
            <Row height="auto">
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'fields.invoices.contact_first_name')}
                  name={INVOICE_FIELDS.contact_first_name}
                  onChange={onFieldChange}
                  value={invoiceState.contact_first_name || ''} />
              </Col2>
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.last_name')}
                  name={INVOICE_FIELDS.contact_last_name}
                  onChange={onFieldChange}
                  value={invoiceState.contact_last_name || ''} />
              </Col2>
            </Row>
            <Row height="auto">
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.company_name')}
                  name={INVOICE_FIELDS.contact_company_name}
                  onChange={onFieldChange}
                  value={invoiceState.contact_company_name || ''} />
              </Col2>
            </Row>
            <Row height="auto">
              <Col2>
                <Subtitle noMargin style={{ marginBottom: '8px' }}>
                  {t(lang, 'forms.contact.header_billing_address')}
                </Subtitle>
                <hr />
                <AddressSearch
                  onChange={onFieldChange}
                  onAddressChange={
                          (addresse: AddressModel) => {
                            setAddressFromGoogle(addresse, AddressType.billing);
                          }
                        }
                  value={{ description: invoiceState.billing_address || '' }}
                  name="billing_address"
                  form={FORM.edit_invoice} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.suite')}
                  name={INVOICE_FIELDS.billing_suite}
                  onChange={onFieldChange}
                  value={invoiceState.billing_suite || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.city')}
                  name={INVOICE_FIELDS.billing_city}
                  onChange={onFieldChange}
                  value={invoiceState.billing_city || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.state')}
                  name={INVOICE_FIELDS.billing_state}
                  onChange={onFieldChange}
                  value={invoiceState.billing_state || ''} />
                <CountriesSelector
                  form={FORM.edit_invoice}
                  onChange={(id) => onFieldChange(id, 'billing_country_id')}
                  value={invoiceState.billing_country_id || undefined}
                  name="billing_country_id" />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.zip_code')}
                  name={INVOICE_FIELDS.billing_zip_code}
                  onChange={onFieldChange}
                  value={invoiceState.billing_zip_code || ''} />
              </Col2>
              <Col2>
                <Subtitle noMargin style={{ marginBottom: '8px' }}>
                  {t(lang, 'forms.contact.header_shipping_address')}
                </Subtitle>
                <hr />
                <AddressSearch
                  onChange={onFieldChange}
                  onAddressChange={
                            (addresse: AddressModel) => {
                              setAddressFromGoogle(addresse, AddressType.shipping);
                            }
                          }
                  value={{ description: invoiceState.shipping_address || '' }}
                  name="shipping_address"
                  form={FORM.edit_invoice} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.suite')}
                  name="shipping_suite"
                  onChange={onFieldChange}
                  value={invoiceState.shipping_suite || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.city')}
                  name="shipping_city"
                  onChange={onFieldChange}
                  value={invoiceState.shipping_city || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.state')}
                  name="shipping_state"
                  onChange={onFieldChange}
                  value={invoiceState.shipping_state || ''} />
                <CountriesSelector
                  form={FORM.edit_invoice}
                  onChange={(id) => onFieldChange(id, 'shipping_country_id')}
                  value={invoiceState.shipping_country_id || undefined}
                  name="shipping_country_id" />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.zip_code')}
                  name="shipping_zip_code"
                  onChange={onFieldChange}
                  value={invoiceState.shipping_zip_code || ''} />
              </Col2>
            </Row>
          </div>
          )}
          <Subtitle style={{ marginBottom: '16px' }}>{t(lang, 'forms.invoices.orders')}</Subtitle>
          <Row>
            <FormCheckbox
              name={INVOICE_FIELDS.issue_tax_receipt}
              disabled={disableCurrentField(INVOICE_FIELDS.issue_tax_receipt)}
              label={t(lang, 'forms.invoices.issue_tax_receipt')}
              checked={Boolean(invoiceState.issue_tax_receipt)}
              onChange={onFieldChange} />
          </Row>
          <Row style={{ marginTop: '16px' }}>
            <FormCheckbox
              name="hasInstallments"
              disabled={disableCurrentField(INVOICE_FIELDS.allow_installment)}
              label="Allow installments"
              checked={!!invoiceState.hasInstallments}
              onChange={onFieldChange} />
          </Row>
          {invoiceState.hasInstallments && (
          <Row>
            <Typography
              variant="body1"
              style={{ color: 'rgb(0, 0, 0, .75)' }}>
              Number of installment(s):
            </Typography>
            <TextField
              style={{ width: '35px' }}
              name="installments"
              type="number"
              onChange={(e) => onInstallmentChange(e.target.value as string)}
              value={invoiceState.installments} />
          </Row>
          )}
          <OrdersDisplayer
            form={FORM.edit_invoice}
            onUpdateProduct={onUpdateProduct}
            orders={orderState}
            onDeleteProduct={onDeleteProduct}
            onAddProduct={onAddProduct}
            onProductChange={onProductChange}
            invoiceState={invoice.state ?? INVOICE_STATE.draft}
            disabled={disableCurrentField(InvoiceOperations.manage_orders)} />
          <Row>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              {t(lang, 'forms.invoices.subtotal')}:&nbsp;
              <Amount>{formatter.format(calculateSubtotal())}</Amount>
            </span>
          </Row>
          <Row>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              <i>{displayTaxes()}</i>
            </span>
          </Row>
          <Row>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              <b>
                Total:&nbsp;
                <Amount>{formatter.format(calculateTotal())}</Amount>
              </b>
            </span>
          </Row>
          <Subtitle style={{ marginBottom: '8px' }}>Meta Data</Subtitle>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.program}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.program_id)}
                form={FORM.edit_invoice}
                name={INVOICE_FIELDS.program_id}
                value={invoiceState.program_id || undefined}
                disabled={disableCurrentField(INVOICE_FIELDS.program_id)}
                noMarginTop />
            </Col2>
            <Col2>
              <MetadataSelector
                type={MetadataType.department}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.department_id)}
                form={FORM.edit_invoice}
                name={INVOICE_FIELDS.department_id}
                value={invoiceState.department_id || undefined}
                disabled={disableCurrentField(INVOICE_FIELDS.department_id)}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto" style={{ marginTop: '16px' }}>
            <Col2>
              <MetadataSelector
                type={MetadataType.account}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.account_id)}
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.account_id}
                value={invoiceState.account_id || undefined}
                disabled={disableCurrentField(INVOICE_FIELDS.account_id)}
                noMarginTop />
            </Col2>
            <Col2>
              <OrganisationBankAccountSelector
                value={invoiceState.bank_account_id || undefined}
                onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.bank_account_id)}
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.bank_account_id}
                disabled={disableCurrentField(INVOICE_FIELDS.bank_account_id)} />
            </Col2>
          </Row>
          <Row height="auto" style={{ marginTop: '16px' }}>
            <FormTextField
              form={FORM.edit_invoice}
              label={t(lang, 'forms.invoices.note')}
              multiline
              minRows="4"
              name={INVOICE_FIELDS.note}
              onChange={onFieldChange}
              value={invoiceState.note || ''}
              disabled={disableCurrentField(INVOICE_FIELDS.note)} />
          </Row>
        </Form>
      </Scroller>
      <ReplaceInvoiceDialog
        handleSubmit={(e) => handleSubmit(e, INVOICE_STATE.final, false)} />
    </>
  );
};

export default EditInvoiceForm;
