import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { t } from 'src/lib/language';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { postDownloadEntitesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { DELIVERY_TYPE, FORMAT, ORIENTATION } from 'src/models/DownloadModel';

interface OwnProps {
  paymentId: number,
}

type Props = OwnProps & ButtonProps;

const UserDownloadPaymentButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { disabled, paymentId } = props;

  const handleOpen = () => {
    dispatch(setFilters({
      entity: ENTITIES.taxReceipts,
      filters: { and: ['tax_receipt.payment_id', '=', paymentId] },
    }));
    dispatch(postDownloadEntitesAction(
      ENTITIES.taxReceipts,
      {
        file: 'payment_receipt.pdf',
        orientation: ORIENTATION.portrait,
        format: FORMAT.pdf,
        delivery: DELIVERY_TYPE.download,
        payment_receipt: false,
      },
    ));
  };

  return (
    <>
      <Tooltip
        title="Download tax receipt"
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={disabled}>
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default UserDownloadPaymentButton;
