import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetAppOutlined';
// import { t } from 'src/lib/language';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { postDownloadSelectedEntitesAction } from 'src/redux/actions/querybuilderActions';
import { DELIVERY_TYPE, FORMAT, ORIENTATION } from 'src/models/DownloadModel';
import { normalizeForForm } from 'src/lib/QueryBuilderHelper';

const UserDownloadReceiptButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const { disabled } = props;

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const isDisabled = useSelector(
    (state: Store) => {
      const result = state.querybuilder.payments?.result?.list || [];
      const normalized = result.map(
        (payment) => normalizeForForm(payment, ENTITIES.payments),
      );
      const payments = normalized.reduce((acc: any, item: any) => (
        { ...acc, [item.id]: item }), {});
      if (!selections.length) { return true; }
      const hasUnpaid = selections.some(
        (id:number) => !payments[id] || payments[id].payment_status !== 'paid',
      );
      return hasUnpaid || disabled;
    },
  );

  const handleOpen = () => {
    dispatch(postDownloadSelectedEntitesAction(
      ENTITIES.taxReceipts,
      selections,
      {
        file: 'Payment receipts.pdf',
        orientation: ORIENTATION.portrait,
        format: FORMAT.pdf,
        delivery: DELIVERY_TYPE.download,
        payment_receipt: true,
      },
      'payment_id',
    ));
  };

  return (
    <>
      <Tooltip
        title="Download receipt"
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={isDisabled}>
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default UserDownloadReceiptButton;
