import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import ManageCustomfields from 'src/components/Elements/ManageCustomfields';

const CustomFieldManagementModal = () => (
  <ModalBase
    name={MODALS.manageCustomFields}
    size={MODALS_SIZE.small}>
    <ManageCustomfields />
  </ModalBase>
);

export default CustomFieldManagementModal;
