import { useSelector } from 'react-redux';
import { verifyUserAction } from 'src/redux/actions/userActions';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Copyright from 'src/components/UI/Copyright';
import { useLocation } from 'react-router-dom';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  text: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  policy: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.775rem',
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  inlineText: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
  },
}));

const VerifiedPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(verifyUserAction(location.search));
  }, []);

  return (
    <>
      <Typography component="h1" variant="h5">
        {t(lang, 'pages.verified.verifying')}
      </Typography>
      <div className={classes.text}>
        <Typography variant="subtitle2">
          {t(lang, 'pages.verified.verifiying_account')}
        </Typography>
        <Typography variant="body2">
          {t(lang, 'pages.verified.wait')}<br />
          <br />
        </Typography>
      </div>
      <Box mt={5} className={classes.copyright}>
        <Copyright />
      </Box>
    </>
  );
};

export default VerifiedPage;
