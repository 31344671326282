import Datagrid from 'src/components/Datagrid/Datagrid';
import {
  ENTITIES,
  QuerbuilderPayloadColumns,
  QuerbuilderPayloadOrder,
} from 'src/models/QuerybuilderModel';
import { setColumns, setOrders } from 'src/redux/actions/querybuilderActions';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import DatagridSearch from 'src/components/DatagridSearch/DatagridSearch';
import DatagridToolbar from 'src/components/DatagridToolbar/DatagridToolbar';
import { FIELD_TYPES } from 'src/constants/Fields';
import { GridColumns } from '@material-ui/x-grid';
import { useEffect } from 'react';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { Preset, PRESET_TYPES } from 'src/models/PresetModel';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';
import TextLink from 'src/components/UI/TextLink';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { t } from 'src/lib/language';
import { useNavigate } from 'react-router-dom';
import routes, { Route } from 'src/constants/routes';

interface DefaultPreset {
  defaultPreset: Nullable<Preset>,
  defaultPresetId: number,
}

const entity = ENTITIES.contacts;
const ContactsPage = () => {
  const dispatch = useAppDispatch();

  const customfields = useSelector((state: Store) => state.customfields);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const user = useSelector((state: Store) => state.user);
  const navigate = useNavigate();
  if (!user.is_admin) {
    navigate(routes[Route.PROFILE].path);
  }

  // PRESET
  const { defaultPreset, defaultPresetId } = useSelector(
    (state: Store): DefaultPreset => {
      const empty = {
        defaultPreset: null,
        defaultPresetId: 0,
      } as DefaultPreset;
      const type = PRESET_TYPES.layout;
      if (!state.presets[entity]) return empty;
      if (!state.presets[entity][type]) return empty;
      const storedPreset = state.presets[entity][type].find((preset) => preset.is_default);
      if (storedPreset) {
        return {
          defaultPreset: storedPreset,
          defaultPresetId: storedPreset.id,
        } as DefaultPreset;
      }
      return empty;
    },
  );

  const getColumns = () => {
    const customfieldColumns = [] as GridColumns;
    if (defaultPreset?.filter) {
      return defaultPreset?.filter;
    }

    Object.values(customfields).forEach((customfield:any) => {
      customfieldColumns.push({
        field: `custom_fields.${customfield.name}`,
        minWidth: 150,
        type: FIELD_TYPES.string,
      });
    });
    return [
      ...DEFAULT_COLUMNS.contacts,
      ...customfieldColumns,
    ];
  };

  useEffect(() => {
    dispatch(setColumns({
      entity,
      columns: getColumns(),
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity,
      order: defaultPreset?.order,
    } as QuerbuilderPayloadOrder));
    dispatch(setSelectedPresets({
      entity,
      type: PRESET_TYPES.layout,
      id: defaultPresetId,
    }));
  }, []);

  return (
    <>
      <DatagridSearch entity={ENTITIES.contacts} />
      <DatagridToolbar entity={ENTITIES.contacts} />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}>
        <TextLink
          id="manage-custom-fields-link"
          onClick={() => dispatch(openModal({ modal: MODALS.manageCustomFields }))}>
          {t(lang, 'misc.custom_fields')}
        </TextLink>
        <TextLink
          id="manage-groups-link"
          onClick={() => dispatch(openModal({ modal: MODALS.manageGroups }))}>
          {t(lang, 'misc.groups')}
        </TextLink>
      </div>
      <Datagrid entity={entity} />
    </>
  );
};
//
export default ContactsPage;
