import {
  changeEmail,
  ChangeEmailData,
  changePassword,
  ChangePasswordData,
  getAdminsData,
  postInviteAdminData,
  getUserData,
  getVerifyUser,
  login,
  LoginData,
  normalizeUserResponseData,
  postRequestResetPassword,
  postResetPassword,
  postUserExists,
  putAdminsData,
  register,
  RegisterData,
  resendVerification,
  ResetPasswordData,
  socialLoginData,
} from 'src/apis/UserAPI';
import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { setLanguage } from 'src/redux/actions/languageActions';
import { getLanguage } from 'src/lib/language';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setFormStatus } from 'src/redux/actions/formActions';
import routes, { Route } from 'src/constants/routes';
import { RIGHT } from 'src/components/Elements/ManageUserAdmins';
import { SOCIAL_PROVIDER } from 'src/constants/Social';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { setInterface } from 'src/redux/actions/interfaceActions';
import { UIType } from 'src/constants/UIType';
import { getOrganisationsUserData, normalizeOrganisationsUserData } from 'src/apis/OrganisationAPI';
import {
  getOrganisationAction,
  setCurrentOrganisation,
  setOrganisationsUserData,
} from 'src/redux/actions/organisationActions';

export const SET_USER_DATA = 'SET_USER_DATA';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const SET_USER_HAS_ORGANISATION = 'SET_USER_HAS_ORGANISATION';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_ADMIN_LIST = 'SET_ADMIN_LIST';

export const setUserData = makeActionCreator(SET_USER_DATA);
export const resetUserData = makeActionCreator(RESET_USER_DATA);
export const setUserHasOrganisation = makeActionCreator(SET_USER_HAS_ORGANISATION);
export const setAuthData = makeActionCreator(SET_AUTH_DATA);
export const setAdminList = makeActionCreator(SET_ADMIN_LIST);

export const getUserDataAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    try {
      const response = await getUserData(organisation);
      if (response.success) {
        const userData = normalizeUserResponseData(response.data);
        const ui = userData.is_admin ? UIType.admin : UIType.user;
        dispatch(setInterface({ ui }));
        dispatch(setUserData(userData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const logUserAction: (data: LoginData) => ThunkedAction<State> =
  (data) => async (dispatch: any) => {
    try {
      dispatch(setFormStatus({ [FORM.login]: FORM_STATUS.processing }));
      const loginResponse = await login(data);
      if (loginResponse.success) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { user, access_token } = loginResponse.data;
        dispatch(setFormStatus({ [FORM.login]: FORM_STATUS.success }));
        const userData = normalizeUserResponseData(user);
        dispatch(setInterface({ ui: userData.is_admin ? UIType.admin : UIType.user }));
        dispatch(setUserData(userData));
        dispatch(setAuthData({
          accessToken: access_token,
          login: true,
          remember: data.remember,
        }));
        dispatch(setLanguage({
          locale: userData.localeId,
          language: getLanguage(userData.localeId),
        }));
        const responseOrganisation = await getOrganisationsUserData();
        if (responseOrganisation.success) {
          const organisationUserList = normalizeOrganisationsUserData(responseOrganisation.data);
          if (userData.has_organisations) {
            const organisations = Object.keys(organisationUserList)
              .map((id: string) => organisationUserList[id]);
            dispatch(setCurrentOrganisation(organisations[0]));
            dispatch(getOrganisationAction());
          }
          dispatch(setOrganisationsUserData(organisationUserList));
          dispatch(getUserDataAction());
        }
        dispatch(closeDialog({ dialog: DIALOGS.login }));
      } else {
        dispatch(setFormStatus({ [FORM.login]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.wrong_credential',
        }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const socialLoginAction: (provider: SOCIAL_PROVIDER, token: string) => ThunkedAction<State> =
  (provider: SOCIAL_PROVIDER, token: string) => async (dispatch) => {
    try {
      dispatch(setFormStatus({ [FORM.login]: FORM_STATUS.processing }));
      const loginResponse = await socialLoginData(provider, token);
      if (loginResponse.success) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { user, access_token } = loginResponse.data;
        dispatch(setFormStatus({ [FORM.login]: FORM_STATUS.success }));
        const userData = normalizeUserResponseData(user);
        dispatch(setUserData(userData));
        dispatch(setAuthData({
          accessToken: access_token,
          login: true,
          remember: true,
        }));
        dispatch(setLanguage({
          locale: userData.localeId,
          language: getLanguage(userData.localeId),
        }));
        const responseOrganisation = await getOrganisationsUserData();
        if (responseOrganisation.success) {
          const organisationUserList = normalizeOrganisationsUserData(responseOrganisation.data);
          if (userData.has_organisations) {
            const organisations = Object.keys(organisationUserList)
              .map((id: string) => organisationUserList[id]);
            dispatch(setCurrentOrganisation(organisations[0]));
            dispatch(getOrganisationAction());
          }
          dispatch(setOrganisationsUserData(organisationUserList));
          dispatch(getUserDataAction());
        }
      }
    } catch (e) { /* Log the error here */
    }
  };

export const registerUserAction: (data: RegisterData) => ThunkedAction<State> =
  (data) => async (dispatch: any) => {
    try {
      dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.processing }));
      const response = await register(data);
      if (response.success) {
        const userData = normalizeUserResponseData(response.data.user);
        dispatch(setUserData(userData));
        dispatch(setAuthData({
          accessToken: response.data.access_token,
          login: true,
          remember: true,
        }));
        dispatch(setLanguage({
          locale: userData.localeId,
          language: getLanguage(userData.localeId),
        }));
        dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.success }));
        window.location.href = routes[Route.NOT_VERIFIED].path;
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.register]: response.errors }));
        dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.success }));
      } else {
        dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const changeUserPassword: (data: ChangePasswordData) => ThunkedAction<State> =
  (data: ChangePasswordData) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.changePassword]: FORM_STATUS.processing }));
    const response = await changePassword(data);
    if (response.success) {
      dispatch(setFormStatus({ [FORM.changePassword]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.email_sent_verification',
      }));
      return;
    }
    dispatch(setFormStatus({ [FORM.changePassword]: FORM_STATUS.error }));
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.email_sent_verification',
    }));
  };

export const changeUserEmail: (data: ChangeEmailData) => ThunkedAction<State> =
  (data: ChangeEmailData) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.changeEmail]: FORM_STATUS.processing }));
    const response = await changeEmail(data);
    if (response.success) {
      dispatch(setFormStatus({ [FORM.changeEmail]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.email_sent_verification',
      }));
      return;
    }
    dispatch(setFormStatus({ [FORM.changeEmail]: FORM_STATUS.error }));
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.email_sent_verification',
    }));
  };

export const resendVerificationAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.processing }));
      const response = await resendVerification();
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.email_sent_verification',
        }));
        dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.success }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credential_error',
      }));
      dispatch(setFormStatus({ [FORM.register]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
    }
  };

export const verifyUserAction: (queryString: string) => ThunkedAction<State> =
  (queryString: string) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.verifying]: FORM_STATUS.processing }));
    const response = await getVerifyUser(queryString);
    if (response.success) {
      const userData = normalizeUserResponseData(response.data);
      dispatch(setUserData(userData));
      dispatch(setFormStatus({ [FORM.verifying]: FORM_STATUS.success }));
      window.location.href = routes[Route.PROFILE].path;
    } else {
      dispatch(setFormStatus({ [FORM.verifying]: FORM_STATUS.error }));
    }
  };

export const requestResetPasswordAction: (email: string) => ThunkedAction<State> =
  (email: string) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.request_reset_password]: FORM_STATUS.processing }));
    const response = await postRequestResetPassword(email);
    if (response.success) {
      dispatch(setFormStatus({ [FORM.request_reset_password]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.email_sent_reset',
      }));
    } else {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credential_error',
      }));
      dispatch(setFormStatus({ [FORM.request_reset_password]: FORM_STATUS.error }));
    }
  };

export const resetPasswordAction:
(passwordData: ResetPasswordData, queryString: string) => ThunkedAction<State> =
  (passwordData: ResetPasswordData, queryString: string) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.reset_password]: FORM_STATUS.processing }));
    const response = await postResetPassword(passwordData, queryString);
    if (response.success) {
      dispatch(setFormStatus({ [FORM.reset_password]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.password_changed',
      }));
      const userData = normalizeUserResponseData(response.data.user);
      dispatch(setUserData(userData));
      dispatch(setAuthData({
        accessToken: response.data.access_token,
        login: true,
        remember: false,
      }));
      dispatch(setLanguage({
        locale: userData.localeId,
        language: getLanguage(userData.localeId),
      }));
      window.location.href = routes[Route.PROFILE].path;
    } else {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credential_error',
      }));
      dispatch(setFormStatus({ [FORM.reset_password]: FORM_STATUS.error }));
    }
  };

export const getAdminsAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getAdminsData(organisation);
      if (response.success) {
        dispatch(setAdminList(response.data));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

export const postInviteAdminAction: (email: string, locale: string) => ThunkedAction<State> =
  (email: string, locale: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.invite_admin]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;

      const response = await postInviteAdminData(organisation, email, locale);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.invite_admin]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.user_saved',
        }));
        dispatch(getAdminsAction());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(setFormStatus({ [FORM.invite_admin]: FORM_STATUS.error }));
    }
  };

export const putAdminsAction: (userId: number, right: RIGHT) => ThunkedAction<State> =
  (userId: number, right: RIGHT) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putAdminsData(organisation, userId, right);
      if (response.success) {
        dispatch(setAdminList(response.data));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

export const postUserExistsAction:
(email: string) => ThunkedAction<State, boolean> =
  (email: string) => async () => {
    const response = await postUserExists(email);
    return !!response.data;
  };
