import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  FormControl, FormHelperText, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ENTITIES, GridColumDefinition, GridColumns } from 'src/models/QuerybuilderModel';
import { f, t } from 'src/lib/language';
import { isEmpty } from 'lodash';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { FORM } from 'src/constants/Form';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { v4 as uuid } from 'uuid';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import { FIELD_TYPES } from 'src/constants/Fields';
import DEFAULT_COLUMNS from 'src/constants/columns';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
  },
  minimalSelect: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

interface Props {
  entity: ENTITIES;
  index: number;
}

const FieldSelector = (props: Props) => {
  const classes = useStyles();

  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.field];
          }
          return '';
        }
        return '';
      },
    );

  const [operator, filterList] = useSelector(
    (state: Store) => {
      const filters = state.advancedSearch[entity];
      if (filters) {
        return getOperatorAndFilters(filters);
      }
      return getOperatorAndFilters(EmptyFilter);
    },
  );

  const customfields = useSelector((state: Store) => state.customfields);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const getCaption = (column: string) => {
    if (isEmpty(column)) {
      return null;
    }
    const [table, property] = column.split('.');
    return (table === 'custom_fields')
      ? customfields[property][`display_${lang}`]
      : f(lang, column);
  };

  const getColumns = () => {
    const columns = DEFAULT_COLUMNS[entity] as GridColumns;

    if (entity === ENTITIES.contacts) {
      const customfieldColumns = [] as GridColumns;
      Object.values(customfields).forEach((customfield: any) => {
        customfieldColumns.push({
          field: `custom_fields.${customfield.name}`,
          minWidth: 150,
          type: FIELD_TYPES.string,
        });
      });
      return [
        ...columns,
        ...customfieldColumns,
      ];
    }
    return columns.sort((a, b) => getCaption(a.field).localeCompare(getCaption(b.field)));
  };

  const handleItemClick = (column: string) => {
    filterList[index][FILTER_POSITION.field] = column;
    filterList[index][FILTER_POSITION.operator] = '';
    filterList[index][FILTER_POSITION.operands] = '';
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  const getItems = () => {
    const elements = [] as any[];
    elements.push(
      <MenuItem
        key={uuid()}
        value=""
        selected={filterList[index][FILTER_POSITION.field] === ''}>
        { t(lang, 'search.selectField') }
      </MenuItem>,
    );
    getColumns().forEach((column: GridColumDefinition) => {
      const [table, property] = column.field.split('.');
      if (table === 'custom_fields') {
        if (customfields[property].type_id === 'multiple-list') {
          return;
        }
        if (customfields[property].type_id === 'single-list') {
          return;
        }
      }
      if (!column.hide_in_search) {
        elements.push(
          <MenuItem
            key={uuid()}
            value={column.field}
            selected={filterList[index][FILTER_POSITION.field] === column.field}>
            { getCaption(column.field) }
          </MenuItem>,
        );
      }
    });
    return elements;
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={!!error}>
        <Select
          value={filterList[index][FILTER_POSITION.field]}
          onChange={(e) => handleItemClick(e.target.value as string)}
          displayEmpty
          classes={{ root: classes.minimalSelect }}
          inputProps={{ 'aria-label': 'Without label' }}
          name={`field-selector-${index}`}
          id={`field-selector-${index}`}>
          { getItems() }
        </Select>
        <FormHelperText>{ error }</FormHelperText>
      </FormControl>
    </div>
  );
};
export default FieldSelector;
