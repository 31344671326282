import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { t } from 'src/lib/language';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import { ProductModel } from 'src/models/ProductModel';
import AddProductForm from 'src/components/Forms/AddProductForm';
import EditProductForm from 'src/components/Forms/EditProductForm';
import { getProductListAction } from 'src/redux/actions/productAction';
import { sortBy } from 'lodash';
import { ProductType } from 'src/constants/Products';
import Item from 'src/components/Elements/Item';
import DeleteButton from 'src/components/Elements/DeleteButton';
import EditButton from 'src/components/UI/EditButton';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useMediaQuery } from 'react-responsive';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageProducts = () => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const MAX_ITEMS = 16;

  useEffect(() => {
    dispatch(getProductListAction());
  }, []);

  const products = useSelector((state: Store) => state.products);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing,
  );

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageProducts }));
  };

  const [currentProduct, setcurrentProduct] = useState({} as ProductModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [type, setType] = useState('' as ProductType);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([] as ProductModel[]);
  const [hasMore, setHasMore] = useState(true);

  const getFiltered = (value?: ProductType):ProductModel[] => {
    const sorted = sortBy(products, (product) => product[`name_${lang}`]);
    let result = sorted;
    if (value) {
      result = sorted.filter((product) => product.type === value);
    }
    return result;
  };

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0; // Reset vertical scroll
    }
  };

  useEffect(() => {
    const filteredItems = getFiltered();
    setPage(1);
    setHasMore(filteredItems.length > MAX_ITEMS);
    setItems(filteredItems.slice(0, MAX_ITEMS));
  }, [products]);

  useEffect(() => {
    if (page > 1) {
      const filteredItems = getFiltered();
      const newItems = filteredItems.slice((page - 1) * MAX_ITEMS, page * MAX_ITEMS);
      setItems((prevItems) => [...prevItems, ...newItems]);
      setHasMore(filteredItems.length > page * MAX_ITEMS);
    }
  }, [page]);

  const handleDelete = (product: ProductModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteProduct, payload: product }));
  };

  const handleEdit = (product: ProductModel) => {
    setcurrentProduct(product);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentProduct({} as ProductModel);
    setCurrentState(STATE.add);
  };

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const threshold = 10; // Adjust this value as needed

    if (scrollHeight - (scrollTop + clientHeight) < threshold) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page to load more items
      }
    }
  }, [hasMore]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentType = (event.target as HTMLInputElement).value as ProductType;
    const filteredItems = getFiltered(currentType);
    setPage(1);
    setHasMore(filteredItems.length > MAX_ITEMS);
    setItems(filteredItems.slice(0, MAX_ITEMS));
    setType(currentType);
    resetScroll();
  };

  const handleSearch = (value: string) => {
    const filtered = getFiltered(type);
    const result = filtered.filter(
      (item) => item[`name_${lang}`]
        .toLowerCase()
        .includes(value.toLowerCase()),
    );
    resetScroll();
    setItems(result);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_product');
      case STATE.edit:
        return t(lang, 'menus.edit_product');
      case STATE.list:
        return t(lang, 'menus.list_product');
      default:
        return t(lang, 'menus.delete_product');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => {
    if (loading) {
      return (
        <div style={{ width: '40px', margin: '0 auto', marginTop: '32px' }}>
          <CircularProgress size={40} />
        </div>
      );
    }
    return items.map(
      (product: ProductModel, index: number) => (
        <Item
          key={`product-${index}`}
          style={getStyle(index)}>
          <span><b>{ product[`name_${lang}`] }</b></span>
          <EditButton onClick={() => handleEdit(product)} title={t(lang, 'menus.edit_product')} />
          <DeleteButton onClick={() => handleDelete(product)} title={t(lang, 'menus.delete_product')} />
        </Item>
      ),
    );
  };

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
        overflowY: 'scroll',
        height: '621px',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      overflowY: 'scroll',
      maxWidth: '680px',
      margin: 'auto',
      height: '621px',
    } as CSSProperties;
  };

  const listItems = () => (
    <div style={getListStyle()} onScroll={handleScroll} ref={scrollableDivRef}>
      {getItems()}
    </div>
  );

  const eEditForm = () => (
    <EditProductForm
      product={currentProduct}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const addForm = () => (
    <AddProductForm
      onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      { currentState === STATE.list && (
        <>
          <Header>
            <Row height="32px">
              <Title>{ getTitle() }</Title>
              <Button
                id="button-close-manage-product"
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleClose}>
                {t(lang, 'misc.close')}
              </Button>
            </Row>
          </Header>
          <ItemSearchBox
            id="button-add-product"
            onAdd={handleAdd}
            title={t(lang, 'menus.add_product')}
            onChange={handleSearch} />
          <Row>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={type}
                onChange={handleTypeChange}>
                <FormControlLabel value="" control={<Radio id="all" />} label="All" />
                <FormControlLabel value={ProductType.product} control={<Radio id="product" />} label="Products" />
                <FormControlLabel value={ProductType.ticket} control={<Radio id="ticket" />} label="Tickets" />
                <FormControlLabel value={ProductType.fee} control={<Radio id="fee" />} label="Fees" />
                <FormControlLabel value={ProductType.donation} control={<Radio id="donation" />} label="Donation" />
              </RadioGroup>
            </FormControl>
          </Row>
        </>
      )}
      <div>
        { currentState === STATE.list && listItems() }
        { currentState === STATE.add && addForm() }
        { currentState === STATE.edit && eEditForm() }
      </div>
    </>
  );
};

export default ManageProducts;
