import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import EditRelationForm from 'src/components/Forms/EditRelationForm';
import { RelationModel } from 'src/models/ContactModel';
import { useEffect, useState } from 'react';
import AddRelationForm from 'src/components/Forms/AddRelationForm';
import { t } from 'src/lib/language';
import { getRelationsAction } from 'src/redux/actions/dataActions';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Header from 'src/components/UI/Header';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import Scroller from 'src/components/UI/Scroller';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageRelations = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRelationsAction());
  }, []);

  const relationsData = useSelector((state: Store) => state.data.relations);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageRelations }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentRelation, setCurrentRelationField] = useState({} as RelationModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  const handleDelete = (relation: RelationModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteRelation, payload: relation.id }));
  };

  const handleEdit = (relation: RelationModel) => {
    setCurrentRelationField(relation);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentRelationField({} as RelationModel);
    setCurrentState(STATE.add);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_relation');
      case STATE.edit:
        return t(lang, 'menus.edit_relation');
      default:
        return t(lang, 'menus.manage_relation');
    }
  };

  const listItems = () => {
    const items = Object.keys(relationsData).map((key:string) => {
      const relation = relationsData[key];
      if (currentFilter) {
        if (
          !relation[`name_${lang}`].toLocaleLowerCase().includes(currentFilter.toLocaleLowerCase())
        ) return null;
      }
      return (
        <Item key={`relation-${key}`}>
          <span><b>{ relation[`name_${lang}`] }</b></span>
          <EditButton onClick={() => handleEdit(relation)} title={t(lang, 'menus.edit_relation')} />
          <DeleteButton onClick={() => handleDelete(relation)} title={t(lang, 'menus.delete_relation')} />
        </Item>
      );
    });
    return (<>{items}</>);
  };

  const editForm = () => (
    <EditRelationForm
      relation={currentRelation}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const addForm = () => (
    <AddRelationForm
      onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      <Header height="56px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'menus.add_relation')}
          onChange={setCurrentFilter} />
      )}
      <Scroller height="560px">
        { currentState === STATE.list && listItems() }
        { currentState === STATE.add && addForm() }
        { currentState === STATE.edit && editForm() }
      </Scroller>
    </>
  );
};

export default ManageRelations;
//
