interface Props {
  children: any,
}

const PaymentInfo = (props: Props) => {
  const { children } = props;
  return (
    <div
      style={{
        fontSize: '0.875rem',
        fontStyle: 'italic',
        display: 'flex',
        alignItems: 'center',
      }}>
      {children}
    </div>
  );
};

export default PaymentInfo;
