import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import WarningOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { t } from 'src/lib/language';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import routes, { Route } from 'src/constants/routes';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { setAuthData } from 'src/redux/actions/userActions';
import PersonIcon from '@material-ui/icons/Person';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { makeStyles } from '@material-ui/core/styles';
import { closeMenu } from 'src/redux/actions/menuActions';
import { useHotkeys } from 'react-hotkeys-hook';

const Spacer = styled.div`
  height: 25px
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#ff9800',
    display: 'inline-block',
    marginRight: '2px',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  donation: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  content: string,
}

export const AdminMenu = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const styles = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const isAICAdmin = useSelector((state: Store) => state.user.is_aic_admin);

  const { content } = props;

  const { hasContact, hasOrganizations } = useSelector((state: Store) => (
    {
      hasContact: state.user.has_contact,
      hasOrganizations: state.user.has_organisations,
    }
  ));

  const hideItem = !hasContact || !hasOrganizations;

  useHotkeys('alt+i', () => navigate(routes[Route.INVOICES].path));
  useHotkeys('alt+p', () => navigate(routes[Route.PAYMENTS].path));
  useHotkeys('alt+c', () => navigate(routes[Route.CONTACTS].path));
  useHotkeys('alt+e', () => navigate(routes[Route.FUNDRAISING].path));

  const handleUserAdminClick = () => {
    dispatch(closeMenu());
    dispatch(openModal({ modal: MODALS.manageUserAdmins }));
  };

  const onClick = () => {
    dispatch(closeMenu());
  };

  const openDonationModal = () => {
    dispatch(openModal({ modal: MODALS.adminDonation }));
  };

  const openShortcutModal = () => {
    dispatch(openModal({ modal: MODALS.shortcutsList }));
  };

  const handleLogout = () => {
    dispatch(closeMenu());
    dispatch(setAuthData({}));
  };

  return (
    <div data-testid="admin-menu">
      <ListItem
        button
        data-testid="admin-menu-invoices"
        component={RouterLink}
        to={routes[Route.INVOICES].path}
        selected={content === Route.INVOICES}
        disabled={hideItem}
        onClick={onClick}>
        <ListItemIcon>
          <DescriptionOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.invoices')}
      </ListItem>

      <ListItem
        button
        data-testid="admin-menu-payments-receipt"
        component={RouterLink}
        to={routes[Route.PAYMENTS].path}
        selected={content === Route.PAYMENTS}
        disabled={hideItem}
        onClick={onClick}>
        <ListItemIcon>
          <AttachMoneyOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.payments_receipt')}
      </ListItem>

      <ListItem
        button
        id="admin-menu-contacts"
        data-testid="admin-menu-contacts"
        component={RouterLink}
        to={routes[Route.CONTACTS].path}
        selected={content === Route.CONTACTS}
        disabled={hideItem}
        onClick={onClick}>
        <ListItemIcon>
          <ContactPhoneOutlinedIcon />
        </ListItemIcon>
        Contacts
      </ListItem>

      <ListItem
        button
        data-testid="admin-menu-fundraising"
        component={RouterLink}
        to={routes[Route.FUNDRAISING].path}
        selected={content === Route.FUNDRAISING}
        disabled={hideItem}
        onClick={onClick}>
        <ListItemIcon>
          <CalendarTodayIcon />
        </ListItemIcon>
        {t(lang, 'menus.fundraising')}
      </ListItem>

      <ListItem
        button
        data-testid="admin-menu-users"
        onClick={handleUserAdminClick}
        disabled={hideItem}>
        <ListItemIcon>
          <SupervisorAccountOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.admin_users')}
      </ListItem>

      <ListItem
        button
        data-testid="admin-menu-settings"
        component={RouterLink}
        to={routes[Route.SETTINGS].path}
        selected={content === Route.SETTINGS}
        onClick={onClick}>
        <ListItemIcon>
          <SettingsOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.settings')}
      </ListItem>
      <Spacer />
      <ListItem
        button
        data-testid="admin-menu-profile"
        component={RouterLink}
        to={routes[Route.PROFILE].path}
        selected={content === Route.PROFILE}
        onClick={onClick}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        { hideItem && <WarningOutlinedIcon className={styles.icon} /> } {t(lang, 'menus.profile')}
      </ListItem>
      <ListItem
        button
        data-testid="admin-menu-admin-organisations"
        component={RouterLink}
        to={routes[Route.ORGANISATIONS].path}
        selected={content === Route.ORGANISATIONS}
        onClick={onClick}>
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
        { hideItem && <WarningOutlinedIcon className={styles.icon} /> } {t(lang, 'menus.organisations')}
      </ListItem>
      <Spacer />
      { isAICAdmin && (
        <ListItem
          button
          data-testid="admin-menu-admin-onboarding"
          component={RouterLink}
          to={routes[Route.ONBOARDING].path}
          selected={content === Route.ONBOARDING}
          onClick={onClick}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          {t(lang, 'menus.onboarding')}
        </ListItem>
      )}
      <ListItem
        button
        data-testid="admin-menu-admin-donations"
        onClick={openDonationModal}
        className={styles.donation}>
        <ListItemIcon>
          <FavoriteIcon color="primary" />
        </ListItemIcon>
        {t(lang, 'menus.make_a_donation')}
      </ListItem>
      <ListItem button onClick={() => openShortcutModal()}>
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        Shortcuts reference
      </ListItem>
      <ListItem button onClick={() => handleLogout()}>
        <ListItemIcon>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        Logout
      </ListItem>
    </div>
  );
};
