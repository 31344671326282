import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import * as Yup from 'yup';
import { t } from 'src/lib/language';

import { setError } from 'src/redux/actions/errorsActions';

import { logUserAction, requestResetPasswordAction } from 'src/redux/actions/userActions';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Copyright from 'src/components/UI/Copyright';
import LanguageSelectorLogin from 'src/components/Control/LanguageSelectorLogin';
import { FORM } from 'src/constants/Form';
import { ErrorBag } from 'src/models/ErrorModel';
// import FacebookLoginButton from 'src/components/Social/FacebookLoginButton';
import GoogleLoginButton from 'src/components/Social/GoogleLoginButton';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  policy: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.775rem',
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  inlineText: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
  },
  forgetPassword: {
    width: '100%',
    padding: theme.spacing(2),
  },
  forgetPasswordText: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  language: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

type OnChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

interface Props {
  changePage: (page:string) => void,
}

const LoginPage = (props: Props) => {
  const classes = useStyles();

  const { changePage } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setError({ [FORM.request_reset_password]: {} as ErrorBag }));
    dispatch(setError({ [FORM.login]: {} as ErrorBag }));
  }, []);

  const errorsForgotPassword: ErrorBag =
    useSelector((state: Store) => state.errors[FORM.request_reset_password] || {} as ErrorBag);

  const errorsLogin: ErrorBag =
    useSelector((state: Store) => state.errors[FORM.login] || {} as ErrorBag);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [state, setState] = useState({
    email: '',
    password: '',
    remember: false,
    submitted: false,
    showForgotPassword: false,
    forgetPasswordEmail: '',
  });

  const loginRules: any = Yup.object().shape({
    email: Yup.string()
      .email(t(lang, 'validations.login.invalid_email'))
      .required(t(lang, 'validations.login.email_required')),
    password: Yup.string()
      .required(t(lang, 'validations.login.password_required'))
      .min(6, t(lang, 'validations.login.password_min')),
  });

  const requestResetPasswordRules: any = Yup.object().shape({
    email: Yup.string()
      .email(t(lang, 'validations.login.invalid_email'))
      .required(t(lang, 'validations.login.email_required')),
  });

  const onEmailChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      email: event.target.value,
    });
  };

  const onRememberChange = (event: OnChangeEvent) => {
    const { checked } = event.target as HTMLInputElement;
    setState({
      ...state,
      remember: checked,
    });
  };

  const onPasswordChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      password: event.target.value,
    });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.request_reset_password]: {} as ErrorBag }));
    dispatch(setError({ [FORM.login]: {} as ErrorBag }));
    const { email, password } = state;
    const loginData = {
      email,
      password,
    };
    try {
      loginRules.validateSync(loginData, { abortEarly: false });
    } catch (validationErrors: any) {
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.login]: errorBag }));
      return;
    }
    dispatch(logUserAction(state));
    setState({
      ...state,
      submitted: true,
    });
  };

  const onForgetPasswordEmailChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      forgetPasswordEmail: event.target.value,
    });
  };

  const toggleShowPassword = () => {
    setState({
      ...state,
      showForgotPassword: !state.showForgotPassword,
    });
  };

  const sendForgetPassword = () => {
    dispatch(setError({ [FORM.request_reset_password]: {} as ErrorBag }));
    dispatch(setError({ [FORM.login]: {} as ErrorBag }));
    const { forgetPasswordEmail } = state;
    const data = {
      email: forgetPasswordEmail,
    };
    try {
      requestResetPasswordRules.validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.request_reset_password]: errorBag }));
      return false;
    }
    dispatch(requestResetPasswordAction(state.forgetPasswordEmail));
    return false;
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t(lang, 'pages.login.sign_in')}
      </Typography>
      <div className={classes.language}>
        <span style={{ marginRight: '-5px' }}>{t(lang, 'pages.login.language')}:</span><LanguageSelectorLogin />
      </div>
      <div className={!state.showForgotPassword ? classes.show : classes.hidden}>
        <form className={classes.form} noValidate onSubmit={(event) => onSubmit(event)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username-field"
            label={t(lang, 'pages.login.your_email')}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => onEmailChange(event)}
            error={!!errorsLogin.email}
            helperText={errorsLogin.email && errorsLogin.email.join(',')} />
          <TextField
            data-testid="password-field"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t(lang, 'pages.login.password')}
            type="password"
            id="password-field"
            autoComplete="current-password"
            onChange={(event) => onPasswordChange(event)}
            error={!!errorsLogin.password}
            helperText={errorsLogin.password && errorsLogin.password.join(',')} />
          <FormControlLabel
            control={(
              <Checkbox
                value="remember"
                color="primary"
                onChange={(event) => onRememberChange(event)} />
)}
            label={t(lang, 'pages.login.remember_me')} />
          <div className={classes.social}>
            {/* <FacebookLoginButton /> */}
            <GoogleLoginButton />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            { t(lang, 'pages.login.sign_in') }
          </Button>
        </form>
        <Grid container>
          <Grid item xs>
            <Typography
              variant="body2"
              color="primary"
              onClick={() => toggleShowPassword()}
              className={classes.pointer}>
              { t(lang, 'pages.login.forget_password') }
            </Typography>
          </Grid>
          <Grid item>
            <Link
              component="button"
              variant="body2"
              onClick={() => changePage('register')}
              className={classes.pointer}>
              <div className={classes.inlineText}>{t(lang, 'pages.login.dont_have_account')}&nbsp;</div>
              {t(lang, 'pages.login.sign_up')}
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className={state.showForgotPassword ? classes.show : classes.hidden}>
        <Typography variant="body2" className={classes.forgetPasswordText}>
          {t(lang, 'pages.login.forget_password_text')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="forget_email"
          label={t(lang, 'pages.login.your_email')}
          name="forget_email"
          autoFocus
          onChange={(event) => onForgetPasswordEmailChange(event)}
          error={!!errorsForgotPassword.email}
          helperText={errorsForgotPassword.email && errorsForgotPassword.email.join(',')} />
        <Button
          onClick={() => sendForgetPassword()}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}>
          {t(lang, 'pages.login.send')}
        </Button>
        <div>

          <Link
            component="button"
            variant="body2"
            onClick={() => toggleShowPassword()}
            className={classes.pointer}>
            <div className={classes.inlineText}>{t(lang, 'pages.login.have_account')}</div>
            &nbsp;{t(lang, 'pages.login.login')}
          </Link>
        </div>
      </div>
      <div className={classes.policy}>
        {t(lang, 'pages.login.policy')}<br />
        <Link href="/privacy" variant="body2">{t(lang, 'pages.login.privacy_policy')}</Link>
        &nbsp;&&nbsp;
        <Link href="/service" variant="body2">{t(lang, 'pages.login.tos')}</Link>.
      </div>
      <Box mt={5} className={classes.copyright}>
        <Copyright />
      </Box>
    </>
  );
};

export default LoginPage;
