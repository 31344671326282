import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { lighten } from '@material-ui/core/styles/colorManipulator';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  getReceiptFilter,
  getReceiptRangeDate,
  normalizeReceiptForUser,
} from 'src/lib/PaymentHelper';
import React, {
  CSSProperties, useCallback, useEffect, useRef, useState,
} from 'react';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters, setOptions } from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { ReceiptSections } from 'src/components/Pages/UserReceiptsPage';
import { setSelections } from 'src/redux/actions/selectionActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import palette from 'src/styles/palette';
import Row from 'src/components/UI/Row';
import UserCardItem from 'src/components/Elements/User/UserCardItem';
import UserListItemLabel from 'src/components/Elements/User/UserCardLabel';

interface Props {
  section: ReceiptSections,
}

const ManageUserReceipts = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const dispatch = useAppDispatch();

  const { section } = props;

  const MAX_ITEMS = 7;

  useEffect(() => {
    const [startDate, endDate] = getReceiptRangeDate();
    const filters = getReceiptFilter(
      startDate,
      endDate,
    );
    dispatch(setOptions({
      entity: ENTITIES.taxReceipts,
      options: {
        payment_receipt: section === ReceiptSections.purchase,
      },
    }));
    dispatch(setFilters({
      entity: ENTITIES.taxReceipts,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.taxReceipts));
  }, [section]);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const loading = useSelector(
    (state:Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing,
  );

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.taxReceipts]) {
      return [...state.selected[ENTITIES.taxReceipts]];
    }
    return [];
  });

  const receipts = useSelector(
    (state: Store) => state.querybuilder[ENTITIES.taxReceipts]?.result?.list || [],
  );

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(receipts).slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0; // Reset vertical scroll
    }
  };
  useEffect(() => {
    if (receipts.length) {
      setPage(1);
      setHasMore(receipts.length > MAX_ITEMS);
      setItems(receipts.slice(0, MAX_ITEMS));
      resetScroll();
    }
  }, [section, receipts]);

  const handleOnClick = (id?: number) => {
    if (!id) return;
    const index = selections.indexOf(id);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity: ENTITIES.taxReceipts,
      selections,
    }));
  };

  const handleNext = () => {
    const newItems = receipts.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(receipts.length > (page + 1) * MAX_ITEMS);
  };

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    // Check if the user has scrolled to the bottom
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasMore) {
        handleNext();
      }
    }
  }, [page, hasMore]);

  const getItemStyle = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return {};
    }
    return {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: lighten(palette.primary.main, 0.9),
    };
  };

  const getItems = () => items.map((item) => {
    const receipt = normalizeReceiptForUser(item);
    const date = moment(receipt.issued_date).format('YYYY-MM-DD');
    return (
      <Row style={{ minWidth: '640px' }} key={receipt.id}>
        <UserCardItem
          onClick={() => handleOnClick(receipt.id)}
          style={getItemStyle(receipt.id)}>
          { section === ReceiptSections.tax && (
          <div><UserListItemLabel>#</UserListItemLabel>{receipt.sequence}</div>
          )}
          <div><UserListItemLabel>Issued:</UserListItemLabel>{date}</div>
          <div>
            <UserListItemLabel>Amount:</UserListItemLabel>
            {formatter.format(receipt.amount ?? 0)}
          </div>
          <div>
            <UserListItemLabel>Deductible:</UserListItemLabel>
            {formatter.format(receipt.deductible ?? 0)}
          </div>
        </UserCardItem>
      </Row>
    );
  });

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
        overflowY: 'auto',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      overflowY: 'auto',
      maxWidth: '680px',
      margin: 'auto',
      height: '621px',
    } as CSSProperties;
  };

  const showItems = () => {
    if (loading) {
      return (
        <div style={{ width: '40px', margin: '0 auto', marginTop: '32px' }}>
          <CircularProgress size={40} />
        </div>
      );
    }
    return getItems();
  };

  const displayItems = () => (
    <div style={getListStyle()} onScroll={handleScroll} ref={scrollableDivRef}>
      {showItems()}
    </div>
  );

  return displayItems();
};

export default ManageUserReceipts;
