import { MouseEvent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import ProductModel, { ProductList } from 'src/models/ProductModel';
import { FORM } from 'src/constants/Form';
import { useSelector } from 'react-redux';
import { setError } from 'src/redux/actions/errorsActions';
import { t } from 'src/lib/language';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { capitalize } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  InstallmentModel,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_TYPE,
} from 'src/models/PaymentModel';
import MenuItem from '@material-ui/core/MenuItem';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import TextLink from 'src/components/UI/TextLink';
import DonationModel, { DonationOrderModel } from 'src/models/DonationModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
import UserDonationValidator from 'src/validations/UserDonationValidator';
import { getProductListAction } from 'src/redux/actions/productAction';
import { formatDatetime, getJSLocale } from 'src/lib/FieldHelper';
import Header from 'src/components/UI/Header';
import Title from 'src/components/UI/Title';
import Row from 'src/components/UI/Row';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
import Col2 from 'src/components/UI/Col2';
import UserPaymentAccountSelector from 'src/components/Control/UserPaymentAccountSelector';

const UserMakeDonationForm = () => {
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const products = useSelector((state: Store) => state.products || [] as ProductList);
  const locale = useSelector((state: Store) => getJSLocale(state.language.locale));

  const [userDonationState, setUserDonationState] = useState({
    is_installment: 0,
    payment_type: PAYMENT_TYPE.cc,
    payment_action: PAYMENT_ACTION_TYPE.credit,
  } as DonationModel);
  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment(),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);
  const [currentProductState, setCurrentProductState] = useState({} as ProductModel);
  const [orderDonationState, setOrderDonationState] = useState({} as DonationOrderModel);
  const [allowedInstallment, setAllowedInstallment] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_TYPE.cc);
  useEffect(() => {
    dispatch(getProductListAction());
  }, []);

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  const getPaymentGateway = () => {
    const currentProduct = getProduct(orderDonationState.product_id);
    if (!currentProduct) return 0;
    if (paymentMethod === PAYMENT_TYPE.cc) return currentProduct.payment_gateway_cc_id;
    return currentProduct.payment_gateway_eft_id;
  };

  useEffect(() => {
    if (orderDonationState.product_id) {
      const product = getProduct(orderDonationState.product_id);
      if (product) {
        if (!!product.allow_installment) {
          setAllowedInstallment(product.number_allowed_installments ?? 0);
        }
        setCurrentProductState(product);
        setOrderDonationState({
          ...orderDonationState,
          quantity: 1,
          paid: product?.price ?? 0,
          deductible: (product?.deductible ?? 0) * 100,
          discount: product?.discount ?? 0,
        });
        setUserDonationState({
          ...userDonationState,
          is_installment: product?.allow_installment ?? 0,
          bank_account_id: product.bank_account_id,
          department_id: product.department_id,
          account_id: product.account_id,
          program_id: product.program_id,
          is_deductible: !!product.deductible,
          payment_gateway_id: product.payment_gateway_cc_id,
          payment_gateway_eft_id: product.payment_gateway_eft_id,
          payment_gateway_cc_id: product.payment_gateway_cc_id,
        });
      }
    }
  }, [orderDonationState.product_id]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.userDonation }));
  };

  const onProductChange = (product: ProductModel) => {
    if (!product) return;
    setUserDonationState((prevState) => ({
      ...prevState,
      bank_account_id: product.bank_account_id,
      payment_gateway_cc_id: product.payment_gateway_cc_id,
      payment_gateway_eft_id: product.payment_gateway_eft_id,
      department_id: product.department_id,
      account_id: product.account_id,
      program_id: product.program_id,
    } as DonationModel));
  };

  const onFieldChange = (value: Value, field: string) => {
    setUserDonationState({
      ...userDonationState,
      [field]: value,
    });
  };

  const onOrderChange = (value: Value, field: string) => {
    if (field === 'product_id') {
      const productId = Number(value);
      const product = getProduct(productId);
      if (product) {
        onProductChange(product);
        setUserDonationState((prevState) => ({
          ...prevState,
          bank_account_id: product.bank_account_id,
          title: product[`name_${lang}`],
          department_id: product.department_id,
          account_id: product.account_id,
          program_id: product.program_id,
          payment_gateway_cc_id: product.payment_gateway_cc_id,
          payment_gateway_eft_id: product.payment_gateway_eft_id,
        }));
      }
    }
    setOrderDonationState({
      ...orderDonationState,
      [field]: value,
    });
  };

  const onInstallmentChange = (value: Value, field: string) => {
    if (field === 'number') {
      const number = value ?? 0;
      if (number > allowedInstallment) return;
    }
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addUserCreditCard,
    }));
  };

  const displayInstallmentFrequencyOptions = () => (
    Object.values(PAYMENT_FREQUENCY).map((frequency) => (
      <MenuItem value={frequency}>{frequency}</MenuItem>
    ))
  );

  const DisplayInstallmentDetails = () => {
    const step = (orderDonationState.paid ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <p>
          <strong>
            {installmentState.number} {capitalize(installmentState.frequency)} {t(lang, 'forms.payments.payments_of')} {step.toFixed(2)}$ {' '}
            {t(lang, 'forms.payments.starting')} {formatDatetime(installmentState.start_date.toDate(), locale)}
          </strong>
        </p>
      );
    }
    return <></>;
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close:boolean = false) => {
    event.preventDefault();
    dispatch(setError({ [FORM.post_purchase]: {} }));

    try {
      UserDonationValidator(lang).validateSync({
        product_id: orderDonationState.product_id,
        paid: orderDonationState.paid,
        payment_info_id: userDonationState.payment_info_id,
        bank_account_id: userDonationState.bank_account_id,
      }, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.post_purchase]: errorBag }));
      return false;
    }
    const payload = {
      ...userDonationState,
      orders: [{
        ...orderDonationState,
        deductible: (orderDonationState.deductible ?? 0) / 100,
      }],
      installment: installmentState,
      payment_gateway_id: getPaymentGateway(),
    } as any;
    dispatch<any>(postPurchaseAction(payload, MODALS.userDonation));
    return close;
  };

  return (
    <>
      <Header height="85px">
        <Row>
          <Title>{t(lang, 'forms.payments.make_donation')}</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            Close
          </Button>
        </Row>
        <Row>
          <Tooltip
            title="Make a Donation"
            placement="top-start">
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}>
                <AttachMoney />
              </ActionButton>
            </span>
          </Tooltip>
        </Row>
      </Header>
      <Scroller height="654px">
        <Form>
          { !userDonationState.is_installment && (
          <Row>
            <div style={{ width: '283px' }}>
              <FormDatePicker
                minDate={moment().startOf('day')}
                form={FORM.make_donation}
                required
                label={t(lang, 'forms.payments.start_date')}
                name="start_date"
                onChange={
                  (value: MaterialUiPickersDate) => onInstallmentChange(
                    moment(value).format('YYYY-MM-DD'),
                    'scheduled_date',
                  )
                }
                value={installmentState.start_date || moment()} />
            </div>
          </Row>
          )}
          <Row>
            <ProductsSelector
              id="product-selector"
              onlyPublic
              onlyDeductible
              form={FORM.post_purchase}
              name="product_id"
              onChange={(product_id: number) => onOrderChange(product_id, 'product_id')}
              value={orderDonationState.product_id ?? 0} />
          </Row>
          <Row alignItems="start">
            <FormTextField
              form={FORM.post_purchase}
              label={t(lang, 'forms.invoices.amount')}
              name="paid"
              onChange={onOrderChange}
              value={orderDonationState.paid}
              disabled={!currentProductState.is_modifiable} />
            <FormTextField
              form={FORM.post_purchase}
              label={`${t(lang, 'forms.invoices.deductible')} (amount)`}
              name="deductible"
              onChange={onOrderChange}
              value={orderDonationState.deductible}
              disabled />
          </Row>
          <Row>
            <FormControl
              component="fieldset"
              style={{ marginTop: '8px' }}>
              <RadioGroup
                defaultValue={0}
                row
                aria-label={t(lang, 'forms.payments.is_installment')}
                name="is_installment"
                value={userDonationState.is_installment}
                onChange={(e) => onFieldChange((e.target.value === '0') ? 0 : 1, 'is_installment')}>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={t(lang, 'forms.payments.single_payment')} />
                <FormControlLabel
                  disabled={!currentProductState.allow_installment}
                  value={1}
                  control={<Radio />}
                  label={t(lang, 'forms.payments.multiple_payment')} />
              </RadioGroup>
            </FormControl>
          </Row>
          { (!!userDonationState.is_installment) && (
          <>
            <Row>
              <Row height="auto" style={{ marginTop: '-16px' }}>
                <i>Max allowed installments: {currentProductState.number_allowed_installments}</i>
              </Row>
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.payments.number')}
                  name="number"
                  onChange={onInstallmentChange}
                  required
                  value={installmentState.number || ''} />
              </Col2>
              <Col2 responsive>
                <FormControl style={{ width: '100%', minWidth: 120 }}>
                  <InputLabel id="frequency">Frequency</InputLabel>
                  <Select
                    labelId="frequency-label-id-"
                    id="demo-simple-select"
                    value={installmentState.frequency || ''}
                    onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}>
                    {displayInstallmentFrequencyOptions()}
                  </Select>
                </FormControl>
              </Col2>
            </Row>
            <Row>
              <div style={{ width: '283px' }}>
                <FormDatePicker
                  form={FORM.make_donation}
                  required
                  label={t(lang, 'forms.payments.start_date')}
                  name="start_date"
                  onChange={
                        (value: MaterialUiPickersDate) => onInstallmentChange(
                          moment(value).format('YYYY-MM-DD'),
                          'start_date',
                        )
                      }
                  value={installmentState.start_date || moment()} />
              </div>
            </Row>
            <Row style={{ marginBottom: '8px' }}>
              <DisplayInstallmentDetails />
            </Row>
          </>
          )}
          <Row>
            <FormControl
              component="fieldset"
              style={{ marginTop: '8px' }}>
              <RadioGroup
                defaultValue={0}
                row
                aria-label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value as PAYMENT_TYPE)}>
                <FormControlLabel
                  value={PAYMENT_TYPE.cc}
                  control={<Radio />}
                  label="Credit Card" />
                <FormControlLabel
                  value={PAYMENT_TYPE.eft}
                  control={<Radio />}
                  label="EFT" />
              </RadioGroup>
            </FormControl>
          </Row>
          <UserPaymentAccountSelector
            value={userDonationState.payment_info_id}
            type={paymentMethod}
            onChange={(id:number) => onFieldChange(id, 'payment_info_id')}
            form={FORM.post_purchase}
            name="payment_info_id" />
          { (paymentMethod === PAYMENT_TYPE.cc) && (
          <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</TextLink>
          )}
          <Row>
            <FormTextField
              form={FORM.post_purchase}
              label={t(lang, 'forms.invoices.note')}
              name="note"
              onChange={onFieldChange}
              value={userDonationState.note}
              multiline
              minRows="4" />
          </Row>
          <Row>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}>
              Donate
            </Button>
          </Row>
        </Form>
      </Scroller>
    </>
  );
};
//
export default UserMakeDonationForm;
