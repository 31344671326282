interface Props {
  bold?: boolean,
  children: any,
  id?: string,
  italic?: boolean,
}

const Amount = (props: Props) => {
  const {
    bold, children, id, italic,
  } = props;
  return (
    <div
      id={id}
      style={{
        width: '105px',
        display: 'inline-block',
        textAlign: 'right',
        fontWeight: bold ? 'bold' : undefined,
        textEmphasis: italic ? 'italic' : undefined,
      }}>
      { children }
    </div>
  );
};

export default Amount;
