import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FORM_ID } from 'src/models/FormModel';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '480px',
    marginTop: 0,
    marginBottom: '8px',
  },
}));

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  onChange: (id:string) => void,
  value?: string,
}

interface CustomfieldTypeItem {
  id: string,
  display: string,
}

const CustomfieldTypeSelector = (props: Props) => {
  const classes = useStyles();

  const {
    disabled, form, name, onChange, value,
  } = props;

  const types = [
    'boolean',
    'date',
    'number',
    'string',
  ];

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const getItems = () => {
    const items:CustomfieldTypeItem[] = [];
    types.sort(
      (a, b) => a.localeCompare(b),
    ).map((item: any) => {
      items.push({
        id: item,
        display: t(lang, `forms.customfields.${item}`),
      } as CustomfieldTypeItem);
      return items;
    });
    return items;
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!errors[name]}
      key={`form-control-${name}`}>
      <FormSingleSelect
        disabled={disabled}
        form={form}
        items={getItems()}
        label={t(lang, 'forms.customfields.type')}
        name={name}
        onChange={onChange}
        value={value || ''}
        noMarginTop />
    </FormControl>
  );
};

export default CustomfieldTypeSelector;
