import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { resetAlert } from 'src/redux/actions/alertActions';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { t } from 'src/lib/language';

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const LocalizedAlert = () => {
  const dispatch = useAppDispatch();
  const {
    code, value, message, type, id,
  } = useSelector((state: Store) => state.alert);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const display = message ?? t(lang, code || '', value);
  const open = !!display;

  const handleClose = () => {
    dispatch(resetAlert());
  };

  const showAlert = () => {
    switch (type) {
      case ALERT_TYPE.error:
        return (
          <Alert onClose={handleClose} severity="error">
            {display}
          </Alert>
        );
      case ALERT_TYPE.warning:
        return (
          <Alert onClose={handleClose} severity="warning">
            {display}
          </Alert>
        );
      case ALERT_TYPE.info:
        return (
          <Alert onClose={handleClose} severity="info">
            {display}
          </Alert>
        );
      case ALERT_TYPE.success:
        return (
          <Alert onClose={handleClose} severity="success">
            {display}
          </Alert>
        );
      default:
        return (
          <Alert onClose={handleClose} severity="info">
            {display}
          </Alert>
        );
    }
  };

  return (
    <Snackbar
      id={`alert-${id || code}`}
      open={open}
      autoHideDuration={30000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      { showAlert() }
    </Snackbar>
  );
};

export default LocalizedAlert;
