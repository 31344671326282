import { MouseEvent, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LanguageModel, LOCALES } from 'src/models/LanguageModel';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(() => createStyles({
  selector: {
    height: 34,
    width: 35,
    marginLeft: 5,
    paddingTop: 8,
    cursor: 'pointer',
  },
  contain: {
    textAlign: 'center',
    width: '100%',
  },
}));

interface Props {
  current: string;
  onChange: (language: LanguageModel) => void;
}

const LanguageSelector = (props: Props) => {
  const classes = useStyles();

  const { current, onChange } = props;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleItemClick = (language: LanguageModel) => {
    onChange(language);
    setAnchorElement(null);
  };

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorElement(null);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div
        className={classes.selector}
        onClick={openMenu}
        onKeyPress={() => {}}
        role="button"
        title="Current language"
        tabIndex={0}>
        <div className={classes.contain}>{ (current ?? '').toUpperCase() }</div>
      </div>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <MenuItem
          key={uuid()}
          onClick={() => handleItemClick({ language: 'en', locale: LOCALES.en_CA })}>
          EN
        </MenuItem>
        <MenuItem
          key={uuid()}
          onClick={() => handleItemClick({ language: 'fr', locale: LOCALES.fr_CA })}>
          FR
        </MenuItem>

      </Menu>
    </div>
  );
};
export default LanguageSelector;
