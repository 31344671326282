import AddCustomfieldForm from 'src/components/Forms/AddCustomfieldForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import { closeModal } from 'src/redux/actions/modalActions';
import { Store, useAppDispatch } from 'src/redux/Store';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import Header from 'src/components/UI/Header';
import { useSelector } from 'react-redux';

const AddCustomfieldModal = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.addCustomfields }));
  };
  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}>
      {t(lang, 'misc.close')}
    </Button>
  );

  return (
    <ModalBase
      name={MODALS.addCustomfields}
      size={MODALS_SIZE.small}>
      <Header height="56px">
        <Row>
          <Title>Add Contact</Title>
          <CloseButton />
        </Row>
      </Header>
      <AddCustomfieldForm noBackButton />
    </ModalBase>
  );
};

export default AddCustomfieldModal;
