import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect, useState } from 'react';
import {
  InstallmentModel,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
} from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setError } from 'src/redux/actions/errorsActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import AdminDonationValidator from 'src/validations/AdminDonationValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import { capitalize, keyBy } from 'lodash';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import ContactSelector from 'src/components/Control/ContactSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import PaymentAccountSelector from 'src/components/Control/PaymentAccountSelector';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import TextLink from 'src/components/UI/TextLink';
import Subtitle from 'src/components/UI/Subtitle';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { getOrganisationBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { getGatewayListAction } from 'src/redux/actions/gatewayAction';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import { ProcessingType } from 'src/models/GatewayModel';
import { ProductList } from 'src/models/ProductModel';
import { ProductOrderModel } from 'src/models/InvoiceModel';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import DonationModel from 'src/models/DonationModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getContactAction } from 'src/redux/actions/contactActions';
import { ContactPayload } from 'src/models/ContactModel';
import ManageAccountsButton from 'src/components/DatagridToolbar/buttons/ManageAccountsButton';
import ManageDepartmentsButton
  from 'src/components/DatagridToolbar/buttons/ManageDepartmentsButton';
import ManageProgramsButton from 'src/components/DatagridToolbar/buttons/ManageProgramsButton';
import ManageProductsButton from 'src/components/DatagridToolbar/buttons/ManageProductsButton';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
import Col2 from 'src/components/UI/Col2';
import Col3 from 'src/components/UI/Col3';

const MakeDonationForm = () => {
  const dispatch = useAppDispatch();
  const products = useSelector((state: Store) => state.products || [] as ProductList);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const gateways = useSelector((state: Store) => state.gateways);
  const currentContactId = useSelector(
    (state: Store) => state.currentEntity[ENTITIES.contacts] || 0,
  );
  const processing = useSelector(
    (state: Store) => state.formStatus[FORM.post_purchase] === FORM_STATUS.processing,
  );
  const [contactId, setContactId] = useState(currentContactId);
  const [gatewayCcBankAccountId, setGatewayCcBankAccountId] = useState(0);
  const [gatewayEftBankAccountId, setGatewayEftBankAccountId] = useState(0);
  const { contact: contactInfo } = useSelector(
    (state: Store) => {
      if (!!state.contacts[contactId]) {
        return state.contacts[contactId] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const [orderDonationState, setOrderDonationState] = useState({} as ProductOrderModel);
  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment().startOf('day'),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);
  const [donationState, setDonationState] = useState({
    is_installment: 0,
    payment_type: PAYMENT_TYPE.cc,
    payment_action: PAYMENT_ACTION_TYPE.credit,
    payment_status: PAYMENT_STATUS.scheduled,
    paid_date: moment().startOf('day'),
    scheduled_date: moment().startOf('day'),
  } as DonationModel);
  const isOnlinePayment =
    () => [PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(donationState.payment_type);

  const isOfflinePayment =
    () => ![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(donationState.payment_type);

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  useEffect(() => {
    if (orderDonationState.product_id) {
      const product = getProduct(orderDonationState.product_id);
      if (product) {
        setOrderDonationState({
          ...orderDonationState,
          quantity: 1,
          paid: product?.price ?? 0,
          deductible: (product?.deductible ?? 0) * 100,
          discount: product?.discount ?? 0,
        });
        setDonationState({
          ...donationState,
          bank_account_id: product.bank_account_id,
          department_id: product.department_id,
          account_id: product.account_id,
          program_id: product.program_id,
          is_deductible: !!product.deductible,
          payment_gateway_id: product.payment_gateway_cc_id,
          title: product[`name_${lang}`],
        });
      }
    }
  }, [orderDonationState.product_id]);

  useEffect(() => {
    if (contactInfo) {
      setDonationState({
        ...donationState,
        issue_tax_receipt: Boolean(contactInfo.issue_tax_receipt),
      });
    }
  }, [contactInfo]);

  useEffect(() => {
    dispatch(getOrganisationBankAccountAction());
    dispatch(setError({ [FORM.make_payment]: {} }));
    dispatch(getGatewayListAction());
  }, []);

  const displayInstallmentDetails = () => {
    const step = (orderDonationState.paid ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <span style={{ fontStyle: 'italic' }}>
          {installmentState.number} {capitalize(installmentState.frequency)}&nbsp;
          {t(lang, 'forms.payments.payments_of')}&nbsp;
          {step.toFixed(2)}$
        </span>
      );
    }
    return <></>;
  };

  const getOnlinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const getOfflinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.paid,
        id: PAYMENT_STATUS.paid,
      },
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const displayInstallmentFrequencyOptions = () => (
    PAYMENT_FREQUENCY_LIST.map((frequency:PAYMENT_FREQUENCY, index:number) => (
      <MenuItem value={frequency} key={`payment-frequency-${index}`}>{frequency}</MenuItem>
    ))
  );

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: donationState.contact_id,
      },
    }));
  };

  const openAddBankAccountModal = () => {
    dispatch(openModal({
      modal: MODALS.addBankAccount,
      payload: {
        contact_id: donationState.contact_id,
      },
    }));
  };

  const onOrderChange = (value: Value, field: string) => {
    setOrderDonationState({
      ...orderDonationState,
      [field]: value,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    if (field === 'is_installment') {
      if (!!value) {
        const data = {
          ...donationState,
          start_date: donationState.start_date || moment().startOf('day'),
          is_installment: 1,
        };
        setDonationState(data);
        return;
      }
    }
    setDonationState({
      ...donationState,
      [field]: value,
    });
  };

  const onPaymentTypeChange = (value: PAYMENT_TYPE) => {
    if (isOnlinePayment()) {
      setDonationState({
        ...donationState,
        bank_account_id: (value === PAYMENT_TYPE.cc)
          ? gatewayCcBankAccountId
          : gatewayEftBankAccountId,
        payment_type: value,
        payment_status: PAYMENT_STATUS.scheduled,
      });
      return;
    }

    setDonationState({
      ...donationState,
      payment_type: value,
    });
  };

  const onGatewayChange = (value: number) => {
    const selectedGateway = keyBy(gateways, 'id')[value];
    if (selectedGateway.bank_account_id) {
      if (donationState.payment_type === PAYMENT_TYPE.cc) {
        setGatewayCcBankAccountId(selectedGateway.bank_account_id);
      } else {
        setGatewayEftBankAccountId(selectedGateway.bank_account_id);
      }

      setDonationState({
        ...donationState,
        payment_gateway_id: value,
        bank_account_id: selectedGateway.bank_account_id,
      });
    }
  };

  const onContactChange = (id:number) => {
    onFieldChange(id, 'contact_id');
    setContactId(id);
    dispatch(getContactAction(id, FORM.admin_donation));
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const getDates = () => {
    if (donationState.is_installment) {
      return {
        paid_date: undefined,
        scheduled_date: undefined,
        start_date: donationState.start_date,
      };
    }
    if (donationState.payment_status === PAYMENT_STATUS.paid) {
      return {
        paid_date: donationState.paid_date,
        scheduled_date: undefined,
        start_date: undefined,
      };
    }
    return {
      paid_date: undefined,
      scheduled_date: donationState.scheduled_date,
      start_date: undefined,
    };
  };

  const getOrder = () => ([{
    ...orderDonationState,
    deductible: (orderDonationState.deductible ?? 0) / 100,
  }]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.adminDonation }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.admin_donation]: {} }));
    const data = {
      ...donationState,
      ...orderDonationState,
    };
    if (isOfflinePayment()) {
      data.payment_gateway_id = undefined;
    }
    try {
      AdminDonationValidator(lang).validateSync(data, {
        abortEarly: false,
      });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.admin_donation]: errorBag }));
      return false;
    }

    // INSTALLMENT
    if (donationState.is_installment) {
      const payload = {
        ...donationState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        payment_info_id: isOnlinePayment() ? donationState.payment_info_id : undefined,
        orders: getOrder(),
        installment: installmentState,
      };
      dispatch<any>(postPurchaseAction(payload, MODALS.adminDonation));
      return true;
    }

    // ONLINE
    if (isOnlinePayment()) {
      const payload = {
        ...donationState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        installment: undefined,
        orders: getOrder(),
      };
      dispatch<any>(postPurchaseAction(payload, MODALS.adminDonation));
      return true;
    }

    // OFFLINE
    const payload = {
      ...donationState,
      payment_info_id: undefined,
      paid_date: getDates().paid_date,
      scheduled_date: getDates().scheduled_date,
      start_date: getDates().start_date,
      installment: undefined,
      orders: getOrder(),
    };
    dispatch(postPurchaseAction(payload));
    return true;
  };

  const DisplayPaymentStatusSelector = () => {
    if (isOfflinePayment()) {
      return (
        <FormSingleSelect
          form={FORM.admin_donation}
          name="paymentStatus"
          label={t(lang, 'forms.invoices.payment_status')}
          onChange={(status: string) => onFieldChange(status, 'payment_status')}
          value={donationState.payment_status}
          items={getOfflinePaymentStatus()}
          disabled={processing}
          noMarginTop />
      );
    }
    return (
      <FormSingleSelect
        form={FORM.admin_donation}
        name="paymentStatus"
        label={t(lang, 'forms.invoices.payment_status')}
        onChange={(status: string) => onFieldChange(status, 'payment_status')}
        value={donationState.payment_status || PAYMENT_STATUS.scheduled}
        items={getOnlinePaymentStatus()}
        disabled
        noMarginTop />
    );
  };

  const DisplayGatewaySelector = () => {
    switch (donationState.payment_type) {
      case PAYMENT_TYPE.cc:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={onGatewayChange}
            type={ProcessingType.cc}
            value={donationState.payment_gateway_id}
            noMarginTop />
        );
      case PAYMENT_TYPE.eft:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={onGatewayChange}
            type={ProcessingType.eft}
            value={donationState.payment_gateway_id}
            noMarginTop />
        );
      default:
        return (<></>);
    }
  };

  const DisplayPaymentInfoSelector = () => {
    if (donationState.payment_type === PAYMENT_TYPE.cc) {
      return (
        <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</TextLink>
      );
    }
    if (donationState.payment_type === PAYMENT_TYPE.eft) {
      return (
        <TextLink onClick={() => openAddBankAccountModal()}>{t(lang, 'forms.payments.add_bank_account')}</TextLink>
      );
    }
    return (<></>);
  };

  const DisplayDate = () => {
    if (donationState.is_installment) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.start_date')}
          name="start_date"
          onChange={
            (value: MaterialUiPickersDate) => onInstallmentChange(
              value,
              'start_date',
            )
          }
          value={moment(installmentState.start_date) || moment()} />
      );
    }
    if (isOnlinePayment()) {
      return (
        <FormDatePicker
          form={FORM.admin_donation}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="scheduled_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'scheduled_date',
            )
          }
          value={moment(donationState.scheduled_date)}
          disabled={processing} />
      );
    }
    if (isOfflinePayment()) {
      return (
        <FormDatePicker
          form={FORM.admin_donation}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="paid_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'paid_date',
            )
          }
          value={moment(donationState.paid_date)}
          disabled={processing} />
      );
    }

    return <></>;
  };

  const DisplayTotalAmount = () => (orderDonationState.quantity * (
    orderDonationState.paid - (orderDonationState.discount ?? 0)
  )).toFixed(2);

  const PaymentTypesLabels = () => {
    const PaymentTypeStyle = {
      margin: '0px',
      marginLeft: '-8px',
      marginTop: '-8px',
    };
    return (
      <>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.cc}
              control={<Radio />}
              label={PAYMENT_TYPE.cc}
              key="payment-type-7" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.eft}
              control={<Radio />}
              label={PAYMENT_TYPE.eft}
              key="payment-type-8" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.cash}
              control={<Radio />}
              label={PAYMENT_TYPE.cash}
              key="payment-type-0" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.check}
              control={<Radio />}
              label={PAYMENT_TYPE.check}
              key="payment-type-1" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.inkind}
              control={<Radio />}
              label={PAYMENT_TYPE.inkind}
              key="payment-type-2" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.interac}
              control={<Radio />}
              label={PAYMENT_TYPE.interac}
              key="payment-type-3" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.terminal}
              control={<Radio />}
              label={PAYMENT_TYPE.terminal}
              key="payment-type-4" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.userbalance}
              control={<Radio />}
              label={PAYMENT_TYPE.userbalance}
              key="payment-type-5" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.wired}
              control={<Radio />}
              label={PAYMENT_TYPE.wired}
              key="payment-type-6" />
          </Col2>
        </Row>
      </>
    );
  };

  const Closebutton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}
      disabled={processing}>
      Close
    </Button>
  );

  const PayButton = () => (
    <Tooltip
      title={t(lang, 'forms.payments.make_donation')}
      placement="top-start">
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleSubmit}
          disabled={processing}>
          <AttachMoneyIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Header height="86px">
        <Row height="auto">
          <Title>{t(lang, 'forms.payments.make_donation')}</Title>
          <Closebutton />
        </Row>
        <Row height="auto">
          <PayButton />
          <ManageAccountsButton disabled={processing} />
          <ManageDepartmentsButton disabled={processing} />
          <ManageProgramsButton disabled={processing} />
          <ManageProductsButton disabled={processing} />
        </Row>
      </Header>
      <Scroller height="608px">
        <Form maxWidth={MODALS_SIZE.large}>
          <Row height="auto" alignItems="end">
            <Col2>
              <FormDatePicker
                form={FORM.admin_donation}
                label={t(lang, 'fields.invoices.issued_date')}
                name="issued_date"
                onChange={
                  (value: MaterialUiPickersDate) => onFieldChange(
                    moment(value).format('YYYY-MM-DD'), 'issued_date',
                  )
                }
                value={moment(donationState.issued_date) || moment()}
                disabled={processing} />
            </Col2>
            <Col2>
              <FormCheckbox
                label={t(lang, 'forms.payments.issue_tax_receipt')}
                name="issue_tax_receipt"
                onChange={onFieldChange}
                checked={Boolean(donationState.issue_tax_receipt)}
                disabled={processing} />
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              <ContactSelector
                style={{ minWidth: '100%' }}
                autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
                onChange={(id:number) => onContactChange(id)}
                label={t(lang, 'forms.payments.contact')}
                form={FORM.admin_donation}
                name="contact_id"
                contactId={donationState.contact_id}
                disabled={processing}
                hideEdit={!!!donationState.contact_id} />
            </Col2>
            <Col2>
              <ContactSelector
                style={{ minWidth: '100%' }}
                autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
                onChange={(id:number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
                label={t(lang, 'forms.payments.seller')}
                form={FORM.admin_donation}
                name={INVOICE_FIELDS.seller_id}
                contactId={donationState.seller_id}
                disabled={processing}
                hideEdit={!!!donationState.seller_id} />
            </Col2>
          </Row>
          <Row height="auto">
            <FormTextField
              form={FORM.admin_donation}
              label={t(lang, 'forms.invoices.title')}
              name="title"
              onChange={onFieldChange}
              value={donationState.title}
              disabled={processing} />
          </Row>
          <Row height="auto">
            <ProductsSelector
              id="product-selector"
              form={FORM.admin_donation}
              name="product_id"
              onChange={(id) => onOrderChange(id, 'product_id')}
              value={orderDonationState.product_id ?? 0}
              disabled={processing} />
          </Row>
          <Row height="auto">
            <Col3>
              <FormTextField
                form={FORM.admin_donation}
                label={t(lang, 'forms.invoices.amount')}
                name="paid"
                onChange={onOrderChange}
                value={orderDonationState.paid}
                disabled={processing} />
            </Col3>
            <div style={{ width: '20%' }}>
              <FormTextField
                form={FORM.admin_donation}
                label={t(lang, 'forms.invoices.quantity')}
                name="quantity"
                onChange={onOrderChange}
                value={orderDonationState.quantity}
                disabled={processing} />
            </div>
            { donationState.is_deductible && (
              <Col3>
                <FormTextField
                  form={FORM.admin_donation}
                  label={`${t(lang, 'forms.invoices.deductible')} (amount)`}
                  name="deductible"
                  onChange={onOrderChange}
                  value={orderDonationState.deductible}
                  disabled={processing} />
              </Col3>
            )}
            <Col3>
              <FormTextField
                form={FORM.admin_donation}
                label={t(lang, 'forms.invoices.discount')}
                name="discount"
                onChange={onOrderChange}
                value={orderDonationState.discount}
                disabled={processing} />
            </Col3>
          </Row>
          {orderDonationState.product_id && (
            <strong>
              Total amount: {DisplayTotalAmount()} CA$
            </strong>
          )}
          <Subtitle style={{ marginBottom: '16px' }}>Payment type</Subtitle>
          <Row height="auto">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label={t(lang, 'forms.payments.payment_type')}
                name="payment_type"
                value={donationState.payment_type}
                onChange={(e) => onPaymentTypeChange(e.target.value as PAYMENT_TYPE)}>
                <PaymentTypesLabels />
              </RadioGroup>
            </FormControl>
          </Row>
          { isOnlinePayment() && donationState.contact_id && (
            <Row height="auto">
              <Col2>
                <PaymentAccountSelector
                  value={donationState.payment_info_id || undefined}
                  type={donationState.payment_type}
                  onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
                  form={FORM.admin_donation}
                  name="payment_info_id"
                  contactId={donationState.contact_id as number ?? 1} />
              </Col2>
              <Col2>
                { DisplayPaymentInfoSelector() }
              </Col2>
            </Row>
          )}
          <Row height="auto" alignItems="start">
            <Col2>
              <OrganisationBankAccountSelector
                style={{ minWidth: '100%' }}
                value={donationState.bank_account_id || undefined}
                onChange={(id:number) => onFieldChange(id, 'bank_account_id')}
                form={FORM.admin_donation}
                name="bank_account_id"
                disabled={processing || isOnlinePayment()} />
            </Col2>
            <Col2>
              {DisplayGatewaySelector()}
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              {DisplayPaymentStatusSelector()}
            </Col2>
            <Col2>
              { DisplayDate() }
            </Col2>
          </Row>
          <Row height="auto">
            <FormControl component="fieldset" style={{ marginTop: '8px' }}>
              <FormCheckbox
                name="is_installment"
                label={t(lang, 'forms.payments.is_installment')}
                checked={!!donationState.is_installment}
                onChange={onFieldChange}
                disabled={processing} />
            </FormControl>
          </Row>
          {!!donationState.is_installment && (
            <>
              <Row height="auto">
                <Col2>
                  <FormTextField
                    form={FORM.admin_donation}
                    label={t(lang, 'forms.payments.number')}
                    name="number"
                    onChange={onInstallmentChange}
                    required
                    disabled={processing}
                    value={installmentState.number || ''} />
                </Col2>
                <Col2>
                  <FormControl style={{ width: '100%', minWidth: 120 }}>
                    <InputLabel id="frequency">Frequency</InputLabel>
                    <Select
                      labelId="frequency-label-id-"
                      id="demo-simple-select"
                      value={installmentState.frequency || ''}
                      onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
                      disabled={processing}>
                      {displayInstallmentFrequencyOptions()}
                    </Select>
                  </FormControl>
                </Col2>
              </Row>
              <Row style={{ marginBottom: '8px' }}>
                {displayInstallmentDetails()}
              </Row>
            </>
          )}
          <Subtitle>Meta Data</Subtitle>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.program}
                onChange={(id) => onFieldChange(id, 'program_id')}
                form={FORM.admin_donation}
                name="program_id"
                value={donationState.program_id || undefined}
                noMarginTop
                disabled={processing} />
            </Col2>
            <Col2>
              <MetadataSelector
                type={MetadataType.account}
                onChange={(id) => onFieldChange(id, 'account_id')}
                form={FORM.admin_donation}
                name="account_id"
                value={donationState.account_id || undefined}
                noMarginTop
                disabled={processing} />
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.department}
                onChange={(id) => onFieldChange(id, 'department_id')}
                form={FORM.admin_donation}
                name="department_id"
                value={donationState.department_id || undefined}
                noMarginTop
                disabled={processing} />
            </Col2>
          </Row>
          <Row height="auto">
            <FormTextField
              form={FORM.admin_donation}
              label="Notes"
              multiline
              minRows="4"
              name="note"
              onChange={onFieldChange}
              value={donationState.note || ''}
              disabled={processing} />
          </Row>
        </Form>
      </Scroller>
    </>
  );
};

export default MakeDonationForm;
