const menus = {
  unselect_all: 'Tout désélectionner',
  edit_taxe: 'Modifier taxe',
  delete_taxe: 'Supprimer taxe',
  select_all: 'Tout sélectionner',
  add_contact_to_group: 'Ajout contact à un groupe',
  edit_payment: 'Modifier un paiement',
  clear_filter: 'Supprimer le filtre',
  edit_selected: 'Modifier sélection',
  add_department: 'Ajouter un département',
  edit_department: 'Éditer le département',
  edit_invoice_details: 'Éditer les détails de la factures',
  list_department: 'Lister les départements',
  add_account: 'Ajouter compte',
  edit_account: 'Éditer compte',
  list_account: 'Lister comptes',
  add_program: 'Ajouter programme',
  edit_program: 'Éditer programme',
  list_program: 'Lister programmes',
  export_invoice: 'Exporter de facture',
  export_payment: 'Exportater le(s) paiement(s)',
  add_contact: 'Ajouter un contact',
  add_event: 'Ajouter un évènement',
  cancel_payment: 'Annuler paiement',
  refund_payment: 'Rembourser le(s) paiement(s)',
  download_result: 'Télécharger résultat',
  download_selected: 'Télécharger sélection',
  cant_cancel_online_payment: "Impossible d'annuler un paiement en ligne",
  cant_refund_offline_payment: 'Impossible de rembourser un paiement hors ligne',
  cant_refund_unpaid_payment: 'Impossible de rembourser un paiement impayé',
  delete_selected_invoice: 'Supprimer la facture sélectionnée',
  cancel_selected_invoice: 'Canceller les facture sélectionnée',
  deleting_invoice: 'Suprression de la facture',
  different_invoice_contact: 'Attention ! Certaines factures sélectionnées n\'appartiennent pas à la même personne! Poursuivre?',
  different_contacts: 'Factures avec differents contacts',
  deleting_invoice_confirmation: 'Etes-vous certain de vouloir supprimer le ou les facture(s)?',
  canceling_invoice_confirmation: 'Etes-vous certain de vouloir canceller le ou les facture(s)?',
  is_not_offline_payment: 'WARNING! Uniquement les paiements hors-ligne peuvent être annulés',
  is_not_online_payment: 'WARNING! Uniquement les paiements en ligne peuvent être remboursés',
  is_unpaid_payment: 'WARNING! Uniquement les paiements déjà éffectués peuvent être remboursés',
  cancel_payment_confirmation: 'Etes-vous certain de vouloir annuler le ou les paiement(s)?',
  delete_selected_contact: 'Supprimer le contact sélectionné',
  delete_selected_event: 'Supprimer l\'évènement sélectionné',
  edit_selected_event: 'Éditer l\'évènement sélectionné',
  deleting_contact: 'Suprression du contact',
  deleting_contact_confirmation: 'Êtes-vous certain de vouloir supprimer le contact?',
  deleting_creditcard: 'Suprression de la carte de crédit',
  deleting_creditcard_confirmation: 'Etes-vous certain de vouloir supprimer cette carte de crédit',
  deleting_bankaccount: 'Suprression du compte de banque du contact.',
  deleting_bankaccount_confirmation: 'Êtes-vous certain de vouloir supprimer ce compte de banque',
  download_invoices: 'Télécharger le(s) facture(s)',
  download_payments: 'Télécharger le(s) paiement(s)',
  download_current_invoice: 'Télécharger cette facture',
  duplicate_invoice: 'Dupliquer cette facture',
  show_invoices: 'Voir facture',
  view_payments: 'Voir les paiements',
  manage_products: 'Gérer les produits',
  edit_selected_contact: 'Éditer le ou les contacts sélectioné(s)',
  edit_contact: 'Éditer le contact',
  load_preset_layout: 'Charger réglages des mises en page',
  load_preset_filter: 'Charger réglages filtres',
  load_preset_reports: 'Charger réglages rapports',
  manage_preset_layout: 'Gerer réglage de mise en page',
  manage_preset_filter: 'Gerer réglage des filtres',
  manage_preset_reports: 'Gerer réglage des rapports',
  merge_selected_contact: 'Combiner les contacts sélectionnés?',
  make_payment: 'Effectuer un paiement',
  make_offline_payment: 'Effectuer un paiement hors ligne',
  share_preset: 'Partager avec les membres de votre organisation',
  save_filter_preset: 'Sauvegarder le filtre',
  yes_proceed: 'Oui, poursuivre',
  quick_search: 'Recherche rapide',
  quick_search_term: 'Terme de recherche rapide',
  deleting_customfields: 'Suprression du champs personnalisé',
  deleting_customfields_confirmation: 'Êtes-vous certain de vouloir supprimer le champs personnalisé?',
  deleting_contact_relation: 'Suprression de la relation du contact',
  deleting_contact_relation_confirmation: 'Êtes-vous certain de vouloir supprimer la relation de ce contact?',
  deleting_relation: 'Suprression de la relation',
  deleting_relation_confirmation: 'Êtes-vous certain de vouloir supprimer la relation?',
  deleting_group: 'Suprression du groupe',
  deleting_group_confirmation: 'Êtes-vous certain de vouloir supprimer ce groupe?',
  deleting_account: 'Suprression du compte',
  deleting_account_confirmation: 'Êtes-vous certain de vouloir supprimer ce compte?',
  deleting_department: 'Suprression du département',
  deleting_department_confirmation: 'Êtes-vous certain de vouloir supprimer ce département?',
  deleting_program: 'Suprression du programme',
  deleting_program_confirmation: 'Êtes-vous certain de vouloir supprimer ce programme?',
  deleting_product: 'Suprression du produit',
  deleting_product_confirmation: 'Êtes-vous certain de vouloir supprimer ce produit?',
  deleting_preset: 'Suprression du preset',
  deleting_preset_confirmation: 'Êtes-vous certain de vouloir supprimer ce preset?',
  removing_admin: 'Suppression de l\'administrateur',
  removing_admin_confirmation: 'Êtes-vous certain de vouloir supprimer cet administrateur?',
  customfield_saved: 'Champs personnalisé sauvegardé avec succes.',
  customfield_saved_error: 'Êrreur lors de la sauvegarde du champs personnaliés.',
  customfield_edited: 'Champs personnalisé sauvegardé avec succes..',
  customfield_edited_error: 'Êrreur lors de la sauvegarde du champs personnaliés.',
  customfield_deleted: 'Champs personnalisé supprimé',
  customfield_deleted_error: 'Êrreur lors de la suppression du champs personnalisé',
  add_customfield: 'Ajouté un champs personnalisé',
  edit_customfield: 'Édité un champs personnalisé',
  add_relation: 'Ajout une relation',
  edit_relation: 'Modifier une relation',
  delete_relation: 'Supprimer une relation',
  manage_accounts: 'Gérer compte',
  manage_customfield: 'Gérer les champs personnalisés',
  manage_contact_relations: 'Gérer les relations des contacts',
  manage_groups: 'Gérer groupe de contact',
  manage_relation: 'Gérer relation',
  manage_departments: 'Gérer departements',
  manage_programs: 'Gérer programmes',
  preset_default: 'Faire du réglage le réglage par défaut',
  preset_share: 'Partager réglage',
  preset_save: 'Sauvegarder réglage',
  preset_delete: 'Supprimer réglage',
  preset_edit: 'Modifier réglage',
  preset_reset: 'Réinitialiser les filtres',
  preset_add: 'Ajouter réglage',
  save_contact: 'Enregistrer le contact',
  delete_contact: 'Supprimer le contact',
  show_invoice_contact: 'Voir les factures du contact',
  show_payment_contact: 'Voir les paiements du contact',
  show_invoice_payments: 'Voir les paiements de la facture',
  show_hide_columns: 'Voir/cacher colonnes',
  show_column: 'Voir colonnes',
  hide_column: 'Cacher colonnes',
  delete_account: 'Supprimer compte',
  delete_department: 'Supprimer le département',
  delete_program: 'Supprimer le programme',
  add_product: 'Ajouter un produit',
  edit_product: 'Modifier un produit',
  list_product: 'Lister les produits',
  list_group: 'Lister les groupes',
  delete_product: 'Supprimer un produit',
  send_invoice_by_email: 'Envoyer la/les facture(s) par email',
  save_as_final: 'Sauvegarder comme final',
  save_as_draft: 'Sauvegarder comme brouillon',
  save_product: 'Sauvegarder le produit',
  deleting_tax: 'Supprimer une taxe',
  deleting_tax_confirmation: 'Êtes-vous certain de vouloir supprimer cette taxe?',
  invoices: 'Factures',
  payments_receipt: 'Paiements/Reçus',
  payments: 'Paiements',
  receipts: 'Reçus',
  payment_settings: 'Paramètres paiements',
  profile: 'Profil',
  organisations: 'Organisations',
  make_a_donation: 'Faire un don',
  logout: 'Déconnexion',
  admin_users: 'Administrateurs',
  settings: 'Réglages',
  invoices_for_payment: 'Factures pour le paiement: ',
  payments_for_invoice: 'Paiement pour l\'invoice: ',
  payments_for_contact: 'Paiement pour le contact: ',
  onboarding: 'Ajouter une organisation',
  fundraising: 'Levé de fond',
  add_team: 'Ajouter une équipe',
  edit_team: 'Modifier une équipe',
  delete_team: 'Supprimer une équipe',
  list_team: 'Lister les équipes',
  filter_teams: 'Filtrer les équipes',
  leave_tab: 'Vous avez des données non enregistrées',
  unsaved_data: 'Vous avez des données non enregistrées, êtes-vous sûr de vouloir quitter cet onglet?',
  order: 'Ordonner par date',
};

export default menus;
