import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { AccountItemModel } from 'src/models/AccountListModel';
import { postProgramsAction } from 'src/redux/actions/dataActions';
import ProgramCreationValidator from 'src/validations/ProgramCreationValidator';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void;
}

const AddProgramForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose } = props;

  const [state, setState] = useState({} as AccountItemModel);
  const form = FORM.manage_program;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      ProgramCreationValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }

    dispatch(postProgramsAction(state));
    return true;
  };

  return (
    <Form id="add-account-form">
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_en')}
        name="name_en"
        onChange={onChange}
        required
        value={state.name_en} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_fr')}
        name="name_fr"
        onChange={onChange}
        required
        value={state.name_fr} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_en')}
        name="description_en"
        onChange={onChange}
        required
        value={state.description_en} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_fr')}
        name="description_fr"
        onChange={onChange}
        required
        value={state.description_fr} />
      <BottomRow>
        <Button
          id="button-save-program"
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          id="button-back-program-list"
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key={`submit-button-group-${state.id}`}>
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default AddProgramForm;
