export enum MODALS {
  addContact = 'addContact',
  addInvoice = 'addInvoice',
  viewInvoice = 'viewInvoice',
  addCustomfields = 'addCustomfields',
  invoicesForContact = 'invoicesForContact',
  cancelPayments = 'cancelPayments',
  refundPayments = 'refundPayments',
  paymentsForInvoice = 'paymentsForInvoice',
  invoicesForPayment = 'invoicesForPayment',
  editPayment = 'editPayment',
  deleteContacts = 'deleteContacts',
  deleteInvoice = 'deleteInvoice',
  downloadContactResult = 'downloadContactResult',
  editContacts = 'editContacts',
  editInvoice = 'editInvoice',
  makePayment = 'makePayment',
  makeUserPayment = 'makeUserPayment',
  guessPayment = 'guessPayment',
  mergeContacts = 'mergeContacts',
  addCreditCard = 'addCreditCard',
  addUserCreditCard = 'addUserCreditCard',
  editCreditCard = 'editCreditCard',
  addBankAccount = 'addBankAccount',
  editBankAccount = 'editBankAccount',
  manageCustomFields = 'manageCustomFields',
  manageGroups = 'manageGroups',
  manageRelations = 'manageRelations',
  managePreset = 'managePreset',
  manageAccounts = 'manageAccounts',
  manageDepartments = 'manageDepartments',
  managePrograms = 'managePrograms',
  manageProducts = 'manageProducts',
  manageUserAdmins = 'manageUserAdmins',
  manageTeams = 'manageTeams',
  uploadImage = 'uploadImage',
  chooseOrganisation = 'chooseOrganisation',
  userDonation = 'userDonation',
  adminDonation = 'adminDonation',
  changeEmail = 'changeEmail',
  changePassword = 'changePassword',
  paymentsForContact = 'paymentsForContact',
  ticketIdentifiers = 'ticketIdentifiers',
  addEvent = 'addEvent',
  editEvent = 'editEvent',
  editUserPayment = 'editUserPayment',
  editUserCreditCard = 'editUserCreditCard',
  shortcutsList = 'shortcutsList',
}

export type ModalModel = {
  [modal in MODALS]: {
    opened: boolean,
    payload: any,
  }
};

export interface ModalPayload {
  modal: MODALS,
}
