import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  CreatePresetPayload,
  DeletePresetPayload,
  GetPresetsPayload,
  Preset,
  UpdatePresetPayload,
} from 'src/models/PresetModel';
import {
  createPresetData,
  deletePresetData,
  getPresetsData,
  normalizePresetsData,
  updatePresetData,
} from 'src/apis/PresetAPI';
import Debug from 'src/lib/Debug';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';

export const SET_PRESET = 'SET_PRESET';
export const SET_PRESETS = 'SET_PRESETS';
export const RESET_PRESET = 'RESET_PRESET';

export const setPresets = makeActionCreator(SET_PRESETS);
export const setPreset = makeActionCreator(SET_PRESET);
export const resetPreset = makeActionCreator(RESET_PRESET);

export const getPresetsAction: (payload: GetPresetsPayload) => ThunkedAction<State> =
  (payload: GetPresetsPayload) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const {
      entity,
    } = payload;
    try {
      const response = await getPresetsData(organisation, entity);
      if (response.success) {
        const data = normalizePresetsData(response.data);
        const presets = {
          [entity]: data,
        };
        dispatch(setPresets(presets));
      }
    } catch (e) {
      //
    }
  };

export const createFilterAction:
(payload: CreatePresetPayload) => ThunkedAction<State> =
  // eslint-disable-next-line max-len
  (payload: CreatePresetPayload) => async (dispatch: any, getState: any) => {
    const {
      entity, type,
    } = payload;
    const form = FORM[`manage_${type}`];
    dispatch(setFormStatus({ [form]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    try {
      const response = await createPresetData(
        organisation,
        payload,
      );
      if (response.success) {
        const data = normalizePresetsData(response.data);
        const presets = {
          [entity]: data,
        };
        dispatch(setPresets(presets));
        const result = data[type].filter((preset) => preset.name === payload.name);
        if (result) {
          const newPreset = { ...result[0] };
          dispatch(setSelectedPresets({
            entity,
            type,
            id: newPreset.id,
          }));
        }
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: `messages.preset_${type}_saved`,
        }));
        dispatch(setFormStatus({ [form]: FORM_STATUS.success }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: `messages.preset_${type}_saved_error`,
      }));
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
    } catch (e) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: `messages.preset_${type}_saved_error`,
      }));
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
      Debug.log(e);
    }
  };

export const deletePresetAction: (payload: DeletePresetPayload) => ThunkedAction<State> =
  (payload: DeletePresetPayload) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const {
      entity,
      type,
    } = payload;
    const form = `manage_${type}`;
    dispatch(setFormStatus({ [form]: FORM_STATUS.processing }));
    try {
      const response = await deletePresetData(organisation, payload);
      if (response.success) {
        const data = normalizePresetsData(response.data);
        const actionPayload = {
          [entity]: data,
        };
        dispatch(setPresets(actionPayload));
        const { presets } = getState();
        const entityPresets = presets[entity][type];
        let defaultPresetId = null;
        if (entityPresets) {
          entityPresets.forEach((preset: Preset) => {
            if (preset.is_default) {
              defaultPresetId = preset.id;
            }
          });
        }
        dispatch(setSelectedPresets({
          entity,
          type,
          id: defaultPresetId,
        }));

        // dispatch(setFormStatus({ [form]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: `messages.preset_${type}_deleted`,
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: `messages.preset_${type}_deleted_error`,
      }));
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
    } catch (e) {
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
      Debug.log(e);
    }
  };

export const updatePresetAction: (payload: UpdatePresetPayload) => ThunkedAction<State> =
  (payload: UpdatePresetPayload) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const {
      entity,
      type,
    } = payload;
    const form = FORM[`manage_${type}`];
    dispatch(setFormStatus({ [form]: FORM_STATUS.processing }));
    try {
      const response = await updatePresetData(organisation, payload);
      if (response.success) {
        const data = normalizePresetsData(response.data);
        const presets = {
          [entity]: data,
        };
        dispatch(setPresets(presets));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: `messages.preset_${type}_saved`,
        }));
        dispatch(setFormStatus({ [form]: FORM_STATUS.success }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: `messages.preset_${type}_saved_error`,
      }));
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
    } catch (e) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: `messages.preset_${type}_saved_error`,
      }));
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
      Debug.log(e);
    }
  };
//
