import operators from 'src/i18n/en/search/operators';
import errors from 'src/i18n/en/search/errors';

const search = {
  errors,
  operators,
  and: 'AND',
  or: 'OR',
  true: 'TRUE',
  false: 'FALSE',
  quickSearch: 'Quick Search',
  advancedSearch: 'Advanced Search',
  selectOperator: 'Select an operator',
  selectField: 'Select a field',
  search: 'Search',
  reset: 'Reset',
  addFilter: 'Add filter',
};

export default search;
