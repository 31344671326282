import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddContactBankAccountForm from 'src/components/Forms/AddContactBankAccountForm';

const AddBankAccountModal = () => (
  <ModalBase
    name={MODALS.addBankAccount}
    size={MODALS_SIZE.small}
    height="38vh">
    <AddContactBankAccountForm />
  </ModalBase>
);

export default AddBankAccountModal;
