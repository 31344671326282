import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useEffect } from 'react';
import { getRelationsAction } from 'src/redux/actions/dataActions';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { FORM_ID } from 'src/models/FormModel';

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  label: string,
  onChange: (id:number) => void,
  value?: number | '',
}

interface RelationItem {
  id: number,
  display: string,
}

const ContactRelationSelector = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    disabled, form, name, onChange, value, label,
  } = props;

  const relations = useSelector((state: Store) => state.data.relations);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(getRelationsAction());
  }, []);

  const getItems = () => {
    const items:RelationItem[] = [
      { id: 0, display: 'none' },
    ];
    if (!relations.length) {
      return items;
    }
    relations.sort(
      (a, b) => a[`name_${lang}`].localeCompare(b[`name_${lang}`]),
    ).map((item: any) => {
      items.push({
        id: item.id,
        display: item[`name_${lang}`],
      } as RelationItem);
      return items;
    });
    return items;
  };

  return (
    <FormSingleSelect
      disabled={disabled}
      form={form}
      items={getItems()}
      label={label}
      name={name}
      onChange={(id:number) => onChange(id ?? 0)}
      value={value || ''}
      noMarginTop />
  );
};

export default ContactRelationSelector;
