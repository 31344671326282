import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { closeModal } from 'src/redux/actions/modalActions';
import EditContactBankAccountForm from 'src/components/Forms/EditContactBankAccountForm';

const EditBankAccountdModal = () => {
  const dispatch = useAppDispatch();
  const { accountId, contactId } = useSelector(
    (state:Store) => {
      if (!state.modals[MODALS.editBankAccount] || !state.modals[MODALS.editBankAccount].opened) {
        return {
          accountId: 0,
          contactId: 0,
        };
      }
      return state.modals[MODALS.editBankAccount].payload;
    },
  );

  const onClose = () => {
    dispatch(closeModal({ modal: MODALS.editBankAccount }));
  };

  return (
    <ModalBase
      name={MODALS.editBankAccount}
      size={MODALS_SIZE.small}>
      <EditContactBankAccountForm accountId={accountId} onClose={onClose} contactId={contactId} />
    </ModalBase>
  );
};

export default EditBankAccountdModal;
