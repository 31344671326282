import Datagrid from 'src/components/Datagrid/Datagrid';
import { ENTITIES, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import {
  resetQueryBuilderResult,
  setColumns,
} from 'src/redux/actions/querybuilderActions';
import DatagridSearch from 'src/components/DatagridSearch/DatagridSearch';
import DatagridToolbar from 'src/components/DatagridToolbar/DatagridToolbar';
import { useEffect } from 'react';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { Store, useAppDispatch } from 'src/redux/Store';
import TextLink from 'src/components/UI/TextLink';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useSelector } from 'react-redux';
import { PRESET_TYPES } from 'src/models/PresetModel';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';
import { t } from 'src/lib/language';

const FundraisingPage = () => {
  const dispatch = useAppDispatch();

  const { defaultPresetColumns, defaultPresetId } = useSelector(
    (state: Store) => {
      const result = { defaultPresetColumns: null, defaultPresetId: 0 };
      const entity = ENTITIES.events;
      const type = PRESET_TYPES.layout;
      if (!state.presets[entity]) return result;
      if (!state.presets[entity][type]) return result;
      const defaultPreset = state.presets[entity][type].find((preset) => preset.is_default);
      if (defaultPreset) {
        return {
          defaultPresetColumns: defaultPreset.filter,
          defaultPresetId: defaultPreset.id,
        };
      }
      return result;
    },
  );
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(setSelectedPresets({
      entity: ENTITIES.events,
      type: PRESET_TYPES.layout,
      id: defaultPresetId,
    }));
    dispatch(setColumns({
      entity: ENTITIES.events,
      columns: defaultPresetColumns || DEFAULT_COLUMNS[ENTITIES.events],
    } as QuerbuilderPayloadColumns));
    dispatch(resetQueryBuilderResult({ entity: ENTITIES.events }));
  }, []);

  return (
    <>
      <DatagridSearch entity={ENTITIES.events} />
      <DatagridToolbar entity={ENTITIES.events} />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}>
        <TextLink onClick={() => dispatch(openModal({ modal: MODALS.manageAccounts }))}>
          {t(lang, 'forms.invoices.accounts')}
        </TextLink>
        <TextLink onClick={() => dispatch(openModal({ modal: MODALS.manageDepartments }))}>
          {t(lang, 'forms.invoices.departments')}
        </TextLink>
        <TextLink onClick={() => dispatch(openModal({ modal: MODALS.managePrograms }))}>
          {t(lang, 'forms.invoices.programs')}
        </TextLink>
        <TextLink onClick={() => dispatch(openModal({ modal: MODALS.manageProducts }))}>
          {t(lang, 'forms.invoices.products')}
        </TextLink>
        <TextLink onClick={() => dispatch(openModal({ modal: MODALS.manageTeams }))}>
          Teams
        </TextLink>
      </div>
      <Datagrid entity={ENTITIES.events} />
    </>
  );
};

export default FundraisingPage;
