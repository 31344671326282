import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { t } from 'src/lib/language';
import { ContactGroupModel } from 'src/models/ContactGroupModel';
import { AccountItemModel } from 'src/models/AccountListModel';
import AddAccountForm from 'src/components/Forms/AddAccountForm';
import EditAccountForm from 'src/components/Forms/EditAccountForm';
import { getAccountsAction } from 'src/redux/actions/dataActions';
import { sortBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import EditButton from 'src/components/UI/EditButton';
import Item from 'src/components/Elements/Item';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Scroller from 'src/components/UI/Scroller';
import { useMediaQuery } from 'react-responsive';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageAccounts = () => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const MAX_ITEMS = 16;

  useEffect(() => {
    dispatch(getAccountsAction());
  }, []);

  const accounts = useSelector((state: Store) => state.data.accounts);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.get_accounts] === FORM_STATUS.processing,
  );

  const [currentAccount, setCurrentAccount] = useState({} as AccountItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([] as AccountItemModel[]);
  const [hasMore, setHasMore] = useState(true);

  const getFiltered = () => {
    const sorted = sortBy(
      Object.values(accounts), (group) => group[`name_${lang}`],
    );
    if (filter) {
      return sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(filter.toLowerCase()),
      );
    }
    return sorted;
  };

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0; // Reset vertical scroll
    }
  };

  useEffect(() => {
    if (page > 10) return;
    const filteredItems = getFiltered();
    setPage(1);
    setHasMore(filteredItems.length > MAX_ITEMS);
    setItems(filteredItems.slice(0, MAX_ITEMS));
  }, [accounts, filter]);

  useEffect(() => {
    if (page > 1) {
      const filteredItems = getFiltered();
      const newItems = filteredItems.slice((page - 1) * MAX_ITEMS, page * MAX_ITEMS);
      setItems((prevItems) => [...prevItems, ...newItems]);
      setHasMore(filteredItems.length > page * MAX_ITEMS);
    }
  }, [page]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageAccounts }));
  };

  const handleDelete = (account: ContactGroupModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteAccount, payload: account }));
  };

  const handleEdit = (account: AccountItemModel) => {
    setCurrentAccount(account);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentAccount({} as AccountItemModel);
    setCurrentState(STATE.add);
  };

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const threshold = 10; // Adjust this value as needed

    if (scrollHeight - (scrollTop + clientHeight) < threshold) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page to load more items
      }
    }
  }, [hasMore]);

  const handleSearch = (term: string) => {
    resetScroll();
    setFilter(term);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_account');
      case STATE.edit:
        return t(lang, 'menus.edit_account');
      default:
        return t(lang, 'menus.list_account');
    }
  };

  const getItemStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => {
    if (loading) {
      return (
        <div style={{ width: '40px', margin: '0 auto', marginTop: '32px' }}>
          <CircularProgress size={40} />
        </div>
      );
    }
    return items.map(
      (account: AccountItemModel, index: number) => (
        <Item
          key={`account-${index}`}
          style={getItemStyle(index)}>
          <span><b>{ account[`name_${lang}`] }</b></span>
          <EditButton onClick={() => handleEdit(account)} title={t(lang, 'menus.edit_account')} />
          <DeleteButton onClick={() => handleDelete(account)} title={t(lang, 'menus.delete_account')} />
        </Item>
      ),
    );
  };

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
        overflowY: 'scroll',
        height: '621px',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      overflowY: 'scroll',
      maxWidth: '680px',
      margin: 'auto',
      height: '621px',
    } as CSSProperties;
  };
  const listItems = () => (
    <div style={getListStyle()} onScroll={handleScroll} ref={scrollableDivRef}>
      {getItems()}
    </div>
  );

  const editForm = () => (
    <Scroller height="597px">
      <EditAccountForm
        account={currentAccount}
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  const addForm = () => (
    <Scroller height="597px">
      <AddAccountForm
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  return (
    <>
      <Header height="56px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            id="button-close-manage-account"
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      { currentState === STATE.list && (
        <ItemSearchBox
          id="button-add-account"
          onAdd={handleAdd}
          title={t(lang, 'menus.add_account')}
          onChange={handleSearch} />
      )}
      <div>
        { currentState === STATE.list && listItems() }
        { currentState === STATE.add && addForm() }
        { currentState === STATE.edit && editForm() }
      </div>
    </>
  );
};

export default ManageAccounts;
