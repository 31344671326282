import { Modal, Paper } from '@material-ui/core';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import { MODALS_SIZE } from 'src/models/modal';
import { useMediaQuery } from 'react-responsive';
import { camelToKebab } from 'src/lib/QueryBuilderHelper';

const useStyles = (size: MODALS_SIZE, height: string) => makeStyles((theme) => ({
  modal: {
    margin: 'auto 0',
    marginTop: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(1, 2),
    minHeight: height,
    maxWidth: size,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  container: {
    position: 'relative',
    minHeight: height,
    maxWidth: size,
    margin: 'auto',
  },
  overlay: {
    top: 0,
    left: 0,
    position: 'absolute',
    height,
    maxWidth: size,
    zIndex: 1500,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  close: {},
}));

const useAutofitStyles = (size: MODALS_SIZE, height: string) => makeStyles((theme) => ({
  modal: {
    margin: theme.spacing(8, 16),
  },
  paper: {
    padding: theme.spacing(1, 2),
    minHeight: height,
    width: size,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  container: {
    position: 'relative',
    height,
    minHeight: height,
    width: size,
    margin: 'auto',
  },
}));

const useMobileStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#FFF',
    overflowY: 'auto',
  },
  modal: {},
  paper: {},
}));

interface Props {
  size: MODALS_SIZE,
  height?: string,
  autofit?: boolean;
  children: any,
  name: MODALS,
}

const ModalBase = (props: Props) => {
  const {
    children, name, size, height, autofit,
  } = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });

  const getStyles = () => {
    if (isTabletOrMobile) {
      return useMobileStyles();
    }
    if (autofit) {
      return useAutofitStyles(size, height ?? '80vh')();
    }
    return useStyles(size, height ?? '80vh')();
  };
  const classes = getStyles();

  const dispatch = useAppDispatch();
  const opened = useSelector((state: Store) => state.modals[name]?.opened || false);

  const handleClose = () => {
    dispatch(closeModal({ modal: name }));
  };

  const displayContain = () => {
    if (isTabletOrMobile) {
      return (
        <div className={classes.container}>
          <div className={classes.paper}>
            { children }
          </div>
        </div>
      );
    }
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          { children }
        </Paper>
      </div>
    );
  };

  if (!opened) return (<></>);

  return (
    <Modal
      id={`modal-${camelToKebab(name)}`}
      className={classes.modal}
      open={opened}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus>
      { displayContain() }
    </Modal>
  );
};

export default ModalBase;
