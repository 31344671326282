import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteContactData,
  deleteRelationData,
  getContactData,
  getInvoicesForContactData,
  getUserContactData,
  mergeContactData,
  normalizeContact,
  normalizeInvoicesForContact,
  normalizeUserContact,
  postContactData,
  postRelationData,
  postUserContactData,
  putContactData,
  putRelationData,
  putUserContactData,
  transformDataForApi,
} from 'src/apis/ContactAPI';
import ContactModel, {
  APIContactPayload,
  ContactPayload,
  RelationModel,
} from 'src/models/ContactModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setError } from 'src/redux/actions/errorsActions';
import { setUserData } from 'src/redux/actions/userActions';
import {
  getEntitiesAction,
  setPagination,
} from 'src/redux/actions/querybuilderActions';
import { MODALS } from 'src/models/ModalModel';
import { ENTITIES, QuerbuilderPayloadPagination } from 'src/models/QuerybuilderModel';
import { resetSelections, setSelections } from 'src/redux/actions/selectionActions';
import { setInvoices } from 'src/redux/actions/invoiceActions';
import { EntityStatus, FORM_ID } from 'src/models/FormModel';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { getRelationsAction } from 'src/redux/actions/dataActions';
import Debug from 'src/lib/Debug';
import { setEntityStatus } from 'src/redux/actions/entityStatusActions';

export const SET_USER_CONTACT = 'SET_USER_CONTACT';

export const SET_INVOICES_FOR_CONTACT = 'SET_INVOICES_FOR_CONTACT';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';
export const RESET_CONTACT = 'RESET_CONTACT';

export const setUserContactData = makeActionCreator(SET_USER_CONTACT);
export const setInvoicesForContactAction = makeActionCreator(SET_INVOICES_FOR_CONTACT);
export const updateContactAction = makeActionCreator(UPDATE_CONTACT);
export const removeContactAction = makeActionCreator(REMOVE_CONTACT);
export const deleteContactsAction = makeActionCreator(DELETE_CONTACTS);
export const resetContactAction = makeActionCreator(RESET_CONTACT);

export const getInvoicesForContact: (contactId: number) => ThunkedAction<State> =
  (contactId: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getInvoicesForContactData(contactId, organisation);
      if (response.success) {
        const { ids, result } = response.data;
        const invoices = normalizeInvoicesForContact(result);
        dispatch(setInvoicesForContactAction({ contactId, invoiceIds: ids }));
        dispatch(setInvoices(invoices));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
    }
  };

export const getUserContactAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getUserContactData();
      const { data } = response;

      if (response.success) {
        const contact:ContactModel = normalizeUserContact(data);
        if (!data) {
          dispatch(setUserContactData(null));
          dispatch(setUserData({ has_contact: false }));
          return;
        }
        dispatch(setUserData({ has_contact: true }));
        dispatch(setUserContactData(contact));
      } else if (response.status === 404) {
        dispatch(setUserData({ has_contact: false }));
        dispatch(setUserContactData({}));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
    }
  };

export const postUserContactAction:
(contact: ContactModel) => ThunkedAction<State> =
  (contact: ContactModel) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.processing }));
      const data = {
        ...contact,
        locale_id: getState().user.localeId,
      } as ContactModel;
      const response = await postUserContactData(data);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contact_saved',
          id: 'alert-contact-saved',
        }));
        dispatch(setUserData({ has_contact: true }));
        dispatch(setUserContactData(contact));
        dispatch(getUserContactAction());
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.contact]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      }
    } catch (e) {
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
    }
  };

export const putUserContactAction:
(contact: ContactModel) => ThunkedAction<State> =
  (contact: ContactModel) => async (dispatch: any) => {
    try {
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.processing }));
      const { id, ...data } = contact;
      const response = await putUserContactData(data);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contact_updated',
          id: 'alert-contact-updated',
        }));
        dispatch(setUserData({ has_contact: true }));
        dispatch(getUserContactAction());
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.contact]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_updated_error',
        }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_updated_error',
      }));
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
    }
  };

export const getContactByIdAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    if (!id) {
      return;
    }
    let status = null;
    if (getState().entityStatus[ENTITIES.contacts]) {
      if (getState().entityStatus[ENTITIES.contacts][id]) {
        status = getState().entityStatus[ENTITIES.contacts][id];
      }
    }

    if (status === EntityStatus.error || status === EntityStatus.processing) {
      return;
    }
    dispatch(setEntityStatus({
      entity: ENTITIES.contacts,
      id,
      status: EntityStatus.processing,
    }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getContactData(id, organisation);
      if (response.success) {
        const data:ContactPayload = normalizeContact(response.data);
        dispatch(updateContactAction({ id, data }));
        dispatch(setEntityStatus({
          entity: ENTITIES.contacts,
          id,
          status: EntityStatus.ok,
        }));
        return;
      }
      dispatch(setEntityStatus({
        entity: ENTITIES.contacts,
        id,
        status: EntityStatus.error,
      }));
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setEntityStatus({
        entity: ENTITIES.contacts,
        id,
        status: EntityStatus.error,
      }));
    }
  };

export const getContactAction: (id: number, form?: FORM_ID) => ThunkedAction<State> =
  (id: number, form?:FORM_ID) => async (dispatch: any, getState: any) => {
    if (!id || !form) {
      return;
    }
    try {
      dispatch(setFormStatus({ [form]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await getContactData(id, organisation);
      if (response.success) {
        const data:ContactPayload = normalizeContact(response.data);
        dispatch(updateContactAction({ id, data }));
        dispatch(setFormStatus({ [form]: FORM_STATUS.success }));
      } else {
        dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setFormStatus({ [form]: FORM_STATUS.error }));
    }
  };

export const getContactActionForMerge: (id1: number, id2: number) => ThunkedAction<State> =
  (id1: number, id2: number) => async (dispatch: any, getState:any) => {
    if (!id1 || !id2) {
      return;
    }
    try {
      let error = false;
      dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response1 = await getContactData(id1, organisation);
      if (response1.success) {
        const data1: ContactPayload = normalizeContact(response1.data);
        const response2 = await getContactData(id2, organisation);
        if (response2.success) {
          const data2: ContactPayload = normalizeContact(response2.data);
          dispatch(updateContactAction({ id: id1, data: data1 }));
          dispatch(updateContactAction({ id: id2, data: data2 }));
          dispatch(setFormStatus({ [FORM.get_contact]: FORM_STATUS.success }));
        } else {
          error = true;
        }
      } else {
        error = true;
      }
      if (error) {
        dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_load_error',
      }));
      dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.error }));
    }
  };
type OnChangeType = (id:number) => void;

export const postContactAction:
(
  payload: ContactPayload,
  close: boolean,
  reload: boolean,
  onChange: OnChangeType) => ThunkedAction<State> =
  (
    payload: ContactPayload,
    close: boolean,
    reload: boolean,
    onChange: OnChangeType,
  ) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const data = {
        ...payload,
        contact: transformDataForApi(payload.contact),
      };
      const response = await postContactData(organisation, data);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contact_saved',
          id: 'alert-contact-saved',
        }));
        const contactData:ContactPayload = normalizeContact(response.data);
        const { id } = contactData.contact;
        dispatch(updateContactAction({ id, contactData }));
        if (close) {
          dispatch(closeModal({ modal: MODALS.addContact }));
        }
        if (onChange && id) {
          onChange(id);
        }
        if (reload) {
          dispatch(setPagination({
            entity: ENTITIES.contacts,
            pagination: { size: 50, page: 1 },
          } as QuerbuilderPayloadPagination));
          dispatch(getEntitiesAction(ENTITIES.contacts));
        }
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.contact]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.error }));
    }
  };

export const putContactAction:
(id:number, contact: APIContactPayload) => ThunkedAction<State> =
  (id:number, contact: APIContactPayload) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.put_contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await putContactData(id, organisation, contact);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.put_contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contact_updated',
          id: 'alert-contact-updated',
        }));
        const data:ContactPayload = normalizeContact(response.data);
        dispatch(updateContactAction({ id, data }));
        dispatch(getEntitiesAction(ENTITIES.contacts));
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.contact]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.put_contact]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_updated_error',
        }));
        dispatch(setFormStatus({ [FORM.put_contact]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_updated_error',
      }));
      dispatch(setFormStatus({ [FORM.put_contact]: FORM_STATUS.error }));
    }
  };

export const deleteContactAction: (ids:number[]) => ThunkedAction<State> =
  (ids:number[]) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await deleteContactData(ids, organisation);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contacts_deleted',
          id: 'alert-contact-deleted',
        }));
        dispatch(deleteContactsAction(ids));
        dispatch(closeModal({ modal: MODALS.deleteContacts }));
        dispatch(getEntitiesAction(ENTITIES.contacts));
        dispatch(resetSelections({ entity: ENTITIES.contacts }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contacts_deleted_error',
        }));
        dispatch(closeModal({ modal: MODALS.deleteContacts }));
        dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const mergeContactsAction:
(
  contact_id: number,
  contact_id_to_merge: number,
  setDisabled: (disabled:boolean) => void,
  payload: APIContactPayload,
) => ThunkedAction<State> =
  (
    contact_id: number,
    contact_id_to_merge: number,
    setDisabled: (disabled:boolean) => void,
    payload: ContactPayload,
  ) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await mergeContactData(
        contact_id,
        contact_id_to_merge,
        organisation,
        payload,
      );
      if (response.success) {
        dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.contact_merged',
          id: 'alert-contacts-merged',
        }));
        dispatch(setSelections({ entity: ENTITIES.contacts, selections: [contact_id] }));
        const data:ContactPayload = normalizeContact(response.data);
        dispatch(updateContactAction({ id: data.contact.id, data }));
        dispatch(closeModal({ modal: MODALS.mergeContacts }));
        dispatch(openModal({ modal: MODALS.editContacts }));
        dispatch(getEntitiesAction(ENTITIES.contacts));
      } else {
        setDisabled(false);
        dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_merged_error',
        }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      setDisabled(false);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_merged_error',
      }));
      dispatch(setFormStatus({ [FORM.merge_contact]: FORM_STATUS.error }));
    }
  };

export const postRelationAction:
(payload: RelationModel) => ThunkedAction<State> =
  (payload: RelationModel) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await postRelationData(organisation, payload);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.relation_saved',
          id: 'alert-relation-saved',
        }));
        dispatch(getRelationsAction());
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.add_relation]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.relation_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.relation_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.add_relation]: FORM_STATUS.error }));
    }
  };

export const putRelationAction:
(id:number, payload: RelationModel) => ThunkedAction<State> =
  (id:number, payload: RelationModel) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.edit_relation]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await putRelationData(id, organisation, payload);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.edit_relation]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.relation_saved',
          id: 'alert-relation-saved',
        }));
        dispatch(getRelationsAction());
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.edit_relation]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.edit_relation]: FORM_STATUS.error }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.relation_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.edit_relation]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.relation_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.edit_relation]: FORM_STATUS.error }));
    }
  };

export const deleteRelationAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteRelationData(id, organisation);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.relation_deleted',
          id: 'alert-relation-deleted',
        }));
        dispatch(getRelationsAction());
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.relation_deleted_error',
      }));
    } catch (e) { /* Log the error here */
      Debug.log(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.relation_deleted_error',
      }));
    }
  };
