const messages = {
  cancel_payment: 'Cancel payment',
  contact_saved: 'Contact information successfully saved.',
  contact_saved_error: 'Sorry, something wrong happened when saving your contact information.',
  payment_saved_error: 'Sorry, something wrong happened when making your payment.',
  relation_saved: 'Relation information successfully saved.',
  relation_saved_error: 'Sorry, something wrong happened when saving your relation information.',
  contact_updated: 'Contact information successfully updated.',
  invoice_updated: 'Invoice information successfully updated.',
  invoices_saved: 'Invoice information successfully saved.',
  contacts_deleted: 'Contacts successfully deleted.',
  relation_deleted: 'Relation successfully deleted.',
  invoices_deleted: 'Invoices successfully deleted.',
  invoices_cancelled: 'Invoices successfully cancelled.',
  invoices_cancelled_error: 'Error cancelling invoices.',
  contact_updated_error: 'Sorry, something wrong happened when updating your contact information.',
  organisation_added: '%organisation% added to your organisation list.',
  organisation_added_error: 'Error while adding %organisation% organisation.',
  organisation_updated: 'Organisation updated successfully.',
  organisation_updated_error: 'Error during organisation update.',
  organisation_removed: '%organisation% removed from your organisation list.',
  organisation_updating_error: 'Error while updating information status for %organisation%.',
  sharing_info_updated: 'Sharing information status for %organisation% updated.',
  sharing_info_updated_error: 'Error while updating sharing information status for %organisationName%.',
  wrong_credential: 'Wrong username or password.',
  email_sent_verification: 'An email has been sent containing the verification email.',
  email_sent_reset: 'An email has been sent containing a link to reset your password.',
  credential_error: 'Sorry, something wrong happened.',
  password_changed: 'Password successfully changed.',
  contact_merged: 'Contacts has been merged',
  contact_merged_error: 'Error when merging contacts.',
  field_cant_be_empty: 'This field can no be empty.',
  customfield_saved: 'Custom field saved.',
  customfield_saved_error: 'Error while saving custom field.',
  customfield_edited: 'Custom field saved.',
  customfield_edited_error: 'Error while saving custom field.',
  customfield_deleted: 'Custom field deleted.',
  customfield_deleted_error: 'Error while deleting custom field.',
  group_saved: 'Group saved.',
  group_saved_error: 'Error while saving group.',
  group_deleted: 'Group deleted.',
  group_deleted_error: 'Error while deleting group.',
  credit_card_added: 'Credit card added.',
  credit_card_deleted: 'Credit card deleted.',
  credit_card_deleted_error: 'Error while deleting credit card.',
  bank_account_added: 'Bank account added.',
  bank_account_deleted: 'Bank account deleted.',
  bank_account_deleted_error: 'Error while deleting bank account.',
  saving_layout: 'Saving layout preset',
  saving_filter: 'Saving filter preset',
  preset_layout_saved: 'Layout preset successfully saved.',
  preset_filter_saved: 'Filters preset successfully saved.',
  preset_layout_deleted: 'Layout preset successfully deleted.',
  preset_filter_deleted: 'Filters preset successfully deleted.',
  preset_layout_deleted_error: 'Error while deleting the preset.',
  preset_filter_deleted_error: 'Error while deleting the preset.',
  preset_layout_saved_error: 'Error while saving the preset.',
  preset_filter_saved_error: 'Error while saving the preset.',
  department_saved: 'Department saved',
  department_saved_error: 'Error while saving the department',
  department_deleted: 'Department deleted',
  department_deleted_error: 'Error while deleting the department',
  account_saved: 'Account saved',
  account_saved_error: 'Error while saving the account',
  account_deleted: 'Account deleted',
  account_deleted_error: 'Error while deleting the account',
  program_saved: 'Program saved',
  program_saved_error: 'Error while saving the program',
  program_deleted: 'Program deleted',
  program_deleted_error: 'Error while deleting the program',
  product_saved: 'Product saved',
  product_saved_error: 'Error while deleting the product',
  product_deleted: 'Product deleted',
  product_deleted_error: 'Error while saving the product',
  organisation_info_updated: 'Organisation information updated',
  organisation_info_updated_error: 'Error while updating the organisation information',
  organisation_contact_updated: 'Organisation contact updated',
  organisation_contact_updated_error: 'Error while updating the organisation contact information',
  tax_saved: 'Tax saved',
  tax_saved_error: 'Error while saving the tax',
  deleted_tax: 'Tax deleted',
  deleted_tax_error: 'Error while deleting the tax',
  successfully_uploaded: 'Successfully uploaded',
  error_upload: 'Error while uploading image',
  replace_invoice: 'This invoice will be cancelled and a new one will be created',
  purchased_success: 'Purchased processed with success.',
  payment_update_success: 'Payment updated with success.',
  payment_refund: 'Payment refund successful.',
  payment_refund_error: 'Payment refund error.',
  purchased_error: 'Error during purchase.',
  organisation_created: 'Organisation successfully created.',
  organisation_created_error: 'Error creation organisation.',
  file_not_found: 'File not found.',
};

export default messages;
