import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { Customfield, CustomFieldsPayload, CustomFieldType } from 'src/models/CustomfieldModel';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import CustomfieldTypeSelector from 'src/components/Control/CustomfieldTypeSelector';
import { postCustomFieldsAction } from 'src/redux/actions/customfieldActions';
import CustomfieldCreationValidator from 'src/validations/CustomfieldCreationValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';
import Spacer from 'src/components/UI/Spacer';

interface Props {
  onClose?: () => void;
  noBackButton?: boolean;
}

const AddCustomfieldForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose, noBackButton } = props;

  const [stateCustomfield, setStateCustomfield] = useState({} as Customfield);

  const customfieldsData = useSelector((state: Store) => state.customfields);
  useEffect(() => {
    setStateCustomfield({} as Customfield);
  }, [customfieldsData]);

  useEffect(() => {
    dispatch(setError({ [FORM.add_customfield]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setStateCustomfield({
      ...stateCustomfield,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_customfield]: {} }));
    try {
      CustomfieldCreationValidator(lang).validateSync(stateCustomfield, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.customfields.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_customfield]: errorBag }));
      return false;
    }
    const payload = {
      name: Math.floor(Math.random() * 1000000000000).toString(16),
      display: {
        en_CA: stateCustomfield.display_en,
        fr_CA: stateCustomfield.display_fr,
      },
      list: stateCustomfield.list,
      list_type_id: CustomFieldType.string,
      type_id: stateCustomfield.type_id,
      required: stateCustomfield.required,
    } as unknown as CustomFieldsPayload;
    dispatch(postCustomFieldsAction(payload));
    return true;
  };

  const DisplayListValueField = () => {
    if (
      stateCustomfield.type_id === CustomFieldType.multipleList
      || stateCustomfield.type_id === CustomFieldType.singleList
    ) {
      return (
        <FormTextField
          form={FORM.add_customfield}
          label={t(lang, 'forms.customfields.list')}
          name="list"
          onChange={onChange}
          required
          value={stateCustomfield.list} />
      );
    }
    return (<></>);
  };

  return (
    <Form>
      <FormTextField
        form={FORM.add_customfield}
        label={t(lang, 'forms.customfields.display_en')}
        name="display_en"
        onChange={onChange}
        required
        value={stateCustomfield.display_en} />
      <FormTextField
        form={FORM.add_customfield}
        label={t(lang, 'forms.customfields.display_fr')}
        name="display_fr"
        onChange={onChange}
        required
        value={stateCustomfield.display_fr} />
      <CustomfieldTypeSelector
        form={FORM.add_customfield}
        name="type_id"
        value={stateCustomfield.type_id || ''}
        onChange={(type_id) => onChange(type_id, 'type_id')} />
      <DisplayListValueField />
      <Spacer space={3} />
      <FormCheckbox
        checked={stateCustomfield.required || false}
        label={t(lang, 'forms.customfields.required')}
        name="required"
        onChange={onChange} />
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto', marginRight: '0px' }}
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'misc.save')}
        </Button>
        { !noBackButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            key="submit-button-add-custom">
            {t(lang, 'misc.back_to_list')}
          </Button>
        )}
      </BottomRow>
    </Form>
  );
};

export default AddCustomfieldForm;
//
