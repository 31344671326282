import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

interface CustomProps {
  onOrderChange: (order:string) => void;
}

type Props = CustomProps & ButtonProps;

const OrderButton = (props: Props) => {
  const { disabled, onOrderChange } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const [order, setOrder] = useState('desc');

  const handleOnClick = () => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    setOrder(newOrder);
    onOrderChange(newOrder);
  };

  const getIcon = () => {
    if (order === 'asc') {
      return <ExpandLessIcon />;
    }
    return <ExpandMoreIcon />;
  };

  return (
    <Tooltip
      title={t(lang, 'menus.order_by_date')}
      placement="top-start">
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.selection}
          onClick={() => handleOnClick()}
          disabled={disabled}>
          { getIcon() }
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default OrderButton;
