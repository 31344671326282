export interface GatewayModel {
  bank_account_id?: number,
  gateway_name: string,
  gateway_settings?: GatewaySettingElavon | GatewaySettingBambora,
  gateway_type?: GatewayType,
  processing_type?: ProcessingType,
  id?: number,
}

export interface GatewayPayload {
  gateway_name?: string,
  gateway_settings?: GatewaySettingElavon | GatewaySettingBambora,
  gateway_type?: GatewayType,
  processing_type?: ProcessingType,
}

export enum GatewayType {
  elavon_converge = 'Elavon_Converge',
  bambora = 'bambora',
  stripe = 'stripe',
  paypal = 'paypal',
}

export interface GatewaySettingElavon {
  account_id: string,
  user_id: string,
  transaction_api_key: string,
}

export interface GatewaySettingBambora {
  merchant_id: string,
  batch_api_key: string,
  reporting_api_key: string,
}

export enum ProcessingType {
  eft = 'EFT_ACH',
  cc = 'CREDIT_CARD',
}

export enum OfflinePaymentType {
  inkind = 'in-kind',
  check = 'check',
  cash = 'cash',
  terminal = 'terminal',
  wired = 'wired',
  interac = 'interac',
  userbalance = 'user-balance',
}

export default GatewayModel;

export type GatewayList = Array<GatewayModel>;
