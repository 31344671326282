import { useState } from 'react';
import ManageUserInvoices from 'src/components/Elements/User/ManageUserInvoices';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { useMediaQuery } from 'react-responsive';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import UserPageHeader from 'src/components/Elements/User/UserPageHeader';
import UserInvoiceSearch from 'src/components/Elements/User/UserInvoiceSearch';
import ViewInvoiceButton from 'src/components/DatagridToolbar/buttons/ViewInvoiceButton';
import UserMakePaymentButton from 'src/components/DatagridToolbar/buttons/UserMakePaymentButton';
import UserDownloadInvoiceButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadInvoiceButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { InvoiceModel } from 'src/models/InvoiceModel';
import { normalizeForForm } from 'src/lib/QueryBuilderHelper';
import { resetSelections } from 'src/redux/actions/selectionActions';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobile: {
    marginTop: '16px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

export enum InvoiceSections {
  all = 'all',
  paid = 'paid',
  overdue = 'overdue',
  unpaid = 'unpaid',
}

const UserInvoicesPage = () => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const [section, setSection] = useState(InvoiceSections.unpaid);

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.invoices]) {
      return [...state.selected[ENTITIES.invoices]];
    }
    return [];
  });

  const selectedInvoices = useSelector(
    (state: Store) => {
      const unfiltered = state.querybuilder.invoices?.result?.list || [];
      return unfiltered.map((item) => normalizeForForm(item, ENTITIES.invoices))
        .filter((invoice: InvoiceModel) => selections.includes(invoice.id));
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetSelections({ entity: ENTITIES.invoices }));
    setSection((event.target as HTMLInputElement).value as InvoiceSections);
  };

  const canPayInvoices = () => {
    const enabledOnline = selectedInvoices.filter(
      (invoice) => !!invoice.payment_gateway_cc_id || !!invoice.payment_gateway_eft_id,
    );
    if (!enabledOnline.length) {
      return false;
    }
    const unpaid = selectedInvoices.filter((invoice) => {
      const balance = invoice.balance ?? 0;
      const scheduled = invoice.scheduled ?? 0;
      const amountToPay = balance - scheduled;
      return amountToPay > 0;
    });
    return unpaid.length > 0;
  };

  const showBottomRow = () => (
    <>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{ maxWidth: '680px', width: '100%', margin: '0 auto' }}>
          <UserInvoiceSearch />
        </div>
      </div>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{ maxWidth: '680px', width: '100%', margin: '0 auto' }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={section}
              onChange={handleChange}>
              <FormControlLabel value={InvoiceSections.all} control={<Radio />} label="All" />
              <FormControlLabel value={InvoiceSections.overdue} control={<Radio />} label="Overdue" />
              <FormControlLabel value={InvoiceSections.unpaid} control={<Radio />} label="Unpaid" />
              <FormControlLabel value={InvoiceSections.paid} control={<Radio />} label="Paid" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px', width: '100%', margin: '0 auto', marginBottom: '8px',
        }}>
          <div className={classes.row}>
            <ViewInvoiceButton isUser />
            <UserMakePaymentButton disabled={!canPayInvoices()} />
            <UserDownloadInvoiceButton />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <UserPageHeader
        title="Invoices"
        bottomRow={showBottomRow()} />
      <div style={{ height: '128px' }} />
      <ManageUserInvoices section={section} />
    </>
  );
};
export default UserInvoicesPage;
