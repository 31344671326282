export const headers = {
  DEFAULT: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum API_URL {
  creditcard = '/credit-card',
  contact_relation = '/contact/relations-type',
  invoice = '/invoice',
  guess_invoice = '/invoice-with-hash',
  cancel_invoice = '/invoice/cancel',
  products = '/products',
  gateways = '/gateways',
  product = '/product',
  countries = '/data/countries',
  timezones = '/data/timezones',
  currencies = '/data/currencies',
  accounts = '/organisation/account',
  programs = '/organisation/program',
  departments = '/organisation/department',
  login = '/user/login',
  contact = '/contact',
  contacts = '/contacts',
  contact_credit_cards = '/credit-card/contact',
  contact_groups = '/contact/groups',
  contact_group = '/contact/group',
  bank_account = '/bank-account',
  contact_credit_card = '/credit-card',
  custom_fields = '/custom-fields',
  custom_field = '/custom-field',
  organisation = '/organisation',
  organisationFromHash = '/organisation/hashed',
  organisationExists = '/organisation/exists',
  create_organisation = '/organisation/create',
  organisations = '/organisations',
  organisation_bank_accounts = '/bank-accounts/organisation',
  presets = '/filters',
  preset = '/filter',
  contact_bank_accounts = '/bank-accounts/contact',
  payment = '/payment',
  payment_purchase = '/payment/purchase',
  payments = '/payments',
  register = '/user/register',
  reset_password = '/password/reset',
  request_reset_password = '/password/request',
  taxes = '/taxes',
  settings = '/settings',
  setting = '/setting',
  user = '/user',
  userSearch = '/user/search',
  admins = '/admins',
  adminRight = '/admin/right',
  adminInvite = '/admin/invite',
  user_locale = '/user/locale',
  user_organisations = '/user/organisations',
  user_organisation = '/user/organisation',
  user_resend_verification = '/user/verify/resend',
  user_verify = '/user/verify',
  user_exists = '/user/exists',
  user_credit_cards = '/credit-card/user',
  user_bank_accounts = '/bank-account/user',
  payment_types = '/data/payment-types',
  salutations = '/data/salutations',
  relations = '/contact/relations-type',
  querybuilder = '/',
  google = '/google/maps',
  tax = '/tax',
  socialLogin = '/social',
  changePassword = '/user/password/change',
  changeEmail = '/user/email',
  fundraisings = '/fundraisings',
  fundraising = '/fundraising',
  teams = '/teams',
  team = '/team',
  upload = '/upload',
}
