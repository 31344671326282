import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import { t } from 'src/lib/language';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Header from 'src/components/UI/Header';
import { closeModal } from 'src/redux/actions/modalActions';

const ShortcutsListModal = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.shortcutsList }));
  };
  return (
    <ModalBase
      name={MODALS.shortcutsList}
      size={MODALS_SIZE.small}>
      <Header height="56px">
        <Row>
          <Title>Shortcut references</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      <br />
      <i>alt+i:</i> Open invoices page<br />
      <i>alt+c:</i> Open contacts page<br />
      <i>alt+c:</i> Open payments page<br />
      <i>alt+shift+c:</i> Close/open search panel(quick or advanced)<br />
      <i>alt+q:</i> Open/close advance search<br />
      <i>alt+a:</i> Open/close quicksearch<br />
      <i>alt+m:</i> Open/close menu<br />
      <i>shift+middle button scroll:</i> When overing over the datagrid, scroll it horizontaly<br />
    </ModalBase>
  );
};

export default ShortcutsListModal;
