import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  deleteOrganisationsUserAction,
  getOrganisationListAction,
  getOrganisationsUserAction,
} from 'src/redux/actions/organisationActions';
import Line from 'src/components/UI/Line';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import DeleteOrganisationDialog from 'src/components/Dialogs/DeleteOrganisationDialog';
import { setFormStatus } from 'src/redux/actions/formActions';
import { t } from 'src/lib/language';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { StyleRules } from '@material-ui/styles';
import palette from 'src/styles/palette';
import TextLink from 'src/components/UI/TextLink';

const styles: StyleRules = {
  deleteIcon: {
    marginRight: '4px',
    '&:hover': {
      color: palette.primary.main,
    },
  },
};
const useStyles = makeStyles(() => (styles));

const OrganisationsForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const sharingStatus = useSelector((state: Store) => state.formStatus.user_organisation_share);
  const deleteStatus = useSelector((state: Store) => state.formStatus.user_organisation_delete);
  const [organisationToDelete, setOrganisationToDelete] = useState('');
  const [confirm, setConfirm] = useState(false);
  const processing =
    () => (sharingStatus === FORM_STATUS.processing) || (deleteStatus === FORM_STATUS.processing);

  useEffect(() => {
    dispatch(setFormStatus({ [FORM.user_organisation_share]: null }));
    dispatch(setFormStatus({ [FORM.user_organisation_delete]: null }));
    dispatch(getOrganisationsUserAction());
    dispatch(getOrganisationListAction());
  }, []);

  const handleOpen = () => {
    dispatch(openModal({ modal: MODALS.chooseOrganisation }));
  };

  const confirmDeleteOrganisation = (organisation: string) => {
    setOrganisationToDelete(organisation);
    setConfirm(true);
  };

  const deleteOrganisation = () => {
    dispatch(deleteOrganisationsUserAction(organisationToDelete));
  };

  const DisplayUserOrganisation = () => (
    <>
      { Object.keys(organisationsUserList).map((id: string) => (
        <Box key={`organisations-${id}`}>
          <Line>
            <Tooltip
              title={t(lang, 'forms.organisations.remove_organisation')}
              placement="top-start">
              <DeleteIcon
                fontSize="small"
                style={{
                  color: processing() ? 'rgba(0,0,0,0.5)' : undefined,
                }}
                className={classes.deleteIcon}
                onClick={() => confirmDeleteOrganisation(id)} />
            </Tooltip>
            {organisationsUserList[id][`name_${lang}`]}
          </Line>
        </Box>
      ))}
    </>
  );

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <Box style={{ margin: 0, padding: 0, fontSize: '1.0rem' }}>
          <DisplayUserOrganisation />
        </Box>
      </div>
      <TextLink onClick={() => handleOpen()}>
        {t(lang, 'forms.organisations.add_organisation')}
      </TextLink>
      <DeleteOrganisationDialog
        open={confirm}
        toggleOpen={() => setConfirm(false)}
        onDelete={() => deleteOrganisation()}>
        {t(lang, 'forms.organisations.delete_organisation_confirm')}
      </DeleteOrganisationDialog>
    </div>
  );
};
export default OrganisationsForm;
