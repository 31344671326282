import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { MouseEvent, useState } from 'react';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { BankAccountPayload } from 'src/models/BankAccountModel';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import BankAccountValidator from 'src/validations/BankAccountValidator';
import {
  postOrganisationBankAccountAction,
} from 'src/redux/actions/paymentInfoAction';
import { BANK_ACCOUNT_COUNTRY, BANK_ACCOUNT_TYPE } from 'src/constants/BankAccount';
import CurrenciesSelector from 'src/components/Control/CurrenciesSelector';
import Row from 'src/components/UI/Row';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void;
}

const AddBankAccountForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { onClose } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);

  const [state, setState] = useState({
    organisation_id: organisationId,
    bank_account_type: BANK_ACCOUNT_TYPE.checking,
    bank_country: BANK_ACCOUNT_COUNTRY.ca,
    currency_id: 19,
  } as BankAccountPayload);

  const onCurrencyChange = (id: number) => {
    setState({
      ...state,
      currency_id: id,
    });
  };

  const onChange = (value: string | undefined, field: string) => {
    if (!!value) {
      switch (field) {
        case 'account':
          if (Number.isNaN(Number(value))) return;
          if (value.length > 20) return;
          break;
        case 'bank':
          if (Number.isNaN(Number(value))) return;
          if (value.length > 3) return;
          break;
        case 'branch':
          if (Number.isNaN(Number(value))) return;
          if (value.length > 5) return;
          break;
        case 'bank_country':
          if (value === BANK_ACCOUNT_COUNTRY.ca) {
            setState({
              ...state,
              currency_id: 19,
              [field]: value as BANK_ACCOUNT_COUNTRY,
            });
            return;
          }
          if (value === BANK_ACCOUNT_COUNTRY.us) {
            setState({
              ...state,
              currency_id: 96,
              [field]: value as BANK_ACCOUNT_COUNTRY,
            });
            return;
          }
          break;
        default:
      }
    }
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_bankaccount]: {} }));
    try {
      BankAccountValidator(lang).validateSync(
        state,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.bankaccounts.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_bankaccount]: errorBag }));
      return;
    }
    dispatch(postOrganisationBankAccountAction(state));
  };

  const getBranchRouting = () => (
    state.bank_country === BANK_ACCOUNT_COUNTRY.ca
      ? 'forms.bankaccounts.branch'
      : 'forms.bankaccounts.routing'
  );

  return (
    <div style={{ minHeight: '720px' }}>
      <FormTextField
        form={FORM.add_bankaccount}
        label={t(lang, 'forms.bankaccounts.bank_name')}
        name="bank_name"
        onChange={onChange}
        required
        value={state.bank_name || ''}
        id="bank-name" />
      <FormTextField
        form={FORM.add_bankaccount}
        label={t(lang, 'forms.bankaccounts.bank_holder_name')}
        name="bank_holder_name"
        onChange={onChange}
        required
        value={state.bank_holder_name || ''}
        id="bank-holder-name" />
      <Row>
        <div style={{ width: '50%' }}>
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
            id="bank-account-country">
            <FormLabel component="legend">Account type</FormLabel>
            <RadioGroup
              row
              aria-label="bank_country"
              name="bank_country"
              value={state.bank_country || BANK_ACCOUNT_COUNTRY.ca}
              onChange={(e) => onChange(e.target.value, 'bank_country')}>
              <FormControlLabel
                value={BANK_ACCOUNT_COUNTRY.ca}
                control={(
                  <Radio id="bank-account-country-ca" />
                )}
                label="Canadian" />
              <FormControlLabel
                value={BANK_ACCOUNT_COUNTRY.us}
                control={(
                  <Radio id="bank-account-country-us" />
                )}
                label="US" />
            </RadioGroup>
          </FormControl>
        </div>
      </Row>
      <Row style={{ alignItems: 'start' }}>
        <div style={{ width: '33%' }}>
          <FormTextField
            form={FORM.add_bankaccount}
            label={t(lang, 'forms.bankaccounts.account')}
            name="account"
            onChange={onChange}
            value={state.account || ''}
            id="bank-account-number" />
        </div>
        <div style={{ width: '33%' }}>
          <FormTextField
            form={FORM.add_bankaccount}
            label={t(lang, getBranchRouting())}
            name="branch"
            onChange={onChange}
            required
            value={state.branch || ''}
            id="bank-branch-number" />
        </div>
        {state.bank_country === BANK_ACCOUNT_COUNTRY.ca && (
          <div style={{ width: '33%' }}>
            <FormTextField
              form={FORM.add_bankaccount}
              label={t(lang, 'forms.bankaccounts.bank')}
              name="bank"
              onChange={onChange}
              required
              value={state.bank || ''}
              id="bank-number" />
          </div>
        )}
      </Row>
      <Row style={{ alignItems: 'start' }}>
        <div style={{ width: '33%' }}>
          <CurrenciesSelector
            form={FORM.add_bankaccount}
            onChange={(id: number) => onCurrencyChange(id)}
            value={state.currency_id || 0}
            name="currency_id" />
        </div>
      </Row>
      {state.bank_country === BANK_ACCOUNT_COUNTRY.us && (
      <Row>
        <div style={{ width: '60%', marginTop: '4px' }}>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">{t(lang, 'forms.bankaccounts.type')}</FormLabel>
            <RadioGroup
              row
              aria-label="bank_account_type"
              name="bank_account_type"
              value={state.bank_account_type || BANK_ACCOUNT_TYPE.saving}
              onChange={(e) => onChange(e.target.value, 'bank_account_type')}
              id="bank-account-type">
              <FormControlLabel
                value={BANK_ACCOUNT_TYPE.saving}
                control={<Radio />}
                label="Personnal Saving" />
              <FormControlLabel
                value={BANK_ACCOUNT_TYPE.checking}
                control={<Radio />}
                label="Personnal Chequing" />
            </RadioGroup>
          </FormControl>
        </div>
      </Row>
      )}
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          data-testid="bank-account-save">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={onClose}
          data-testid="back-to-list"
          key={`submit-button-group-${state.id}`}>
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </div>
  );
};

export default AddBankAccountForm;
