import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { InvoiceModel } from 'src/models/InvoiceModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';

interface OwnProps {
  invoiceId?: number;
}

type Props = OwnProps & ButtonProps;

const MakePaymentButton = (props: Props) => {
  const { invoiceId, disabled } = props;
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const invoices = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.invoices[id],
    ) || [] as InvoiceModel[],
  );

  const handleOnClick = () => {
    if (invoiceId) {
      dispatch(openModal({ modal: MODALS.makePayment, payload: [invoiceId] }));
      return;
    }
    let index = 0;
    let previousId = invoices[0].contact_id;
    while (index < invoices.length) {
      const currentId = invoices[index].contact_id;
      if (previousId !== currentId) {
        dispatch(openDialog({ dialog: DIALOGS.confirmPaymentModalDialog }));
        return;
      }
      previousId = currentId;
      index += 1;
    }
    dispatch(openModal({ modal: MODALS.makePayment }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.make_payment')}
      placement="top-start">
      <span>
        <ActionButton
          id="invoices-make-payment"
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleOnClick}
          disabled={disabled}>
          <AttachMoney />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default MakePaymentButton;
