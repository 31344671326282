const misc = {
  add_preset: 'Add preset',
  back_to_top: 'Back to top',
  back_to_list: 'Back to list',
  help: 'Help',
  hide_help: 'Hide help',
  save: 'Save',
  save_and_close: 'Save and Close',
  submit: 'Submit',
  custom_fields: 'Custom fields',
  groups: 'Groups',
  manage_admins: 'Manage admins',
  close: 'Close',
  close_search: 'Close Search',
  delete: 'Delete',
  disconnect: 'Disconnect',
  yes: 'Yes',
  no: 'No',
  y: 'Y',
  n: 'N',
  cancel: 'Cancel',
  download: 'Download',
  update: 'Update',
  export: 'Export',
  cancel_payments: 'Cancel payments',
  refund_payments: 'Refund payment(s)',
  refund: 'Refund',
  add: 'Add',
  send_invite_to_become_admin: 'Send an invitation to be administrator',
  invite: 'Invite',
  filter: 'Filter',
  address_information: 'Address information',
  payment_information: 'Payment information',
  general_information: 'General information',
  connect_to_organisation: 'Connect to an organisation',
  pay: 'Pay',
};

export default misc;
